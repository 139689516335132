import { Callout, FormValues, Stack, StackItem, Text, useDocumentTitle, useViewerTenant } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { AccountLayout } from "~/accounts/AccountLayout";
import { AnonymousSignInForm } from "./AnonymousSignInForm";
import { signOut } from "~/core/auth";
import { useSetRecoilState } from "@workflows/runtime-web";
import { appSidebar } from "~/core/state";
export const AnonymousSignInRoute: React.FC = () => {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const {
    t
  } = useTranslation("ai.workflows.accounts");
  const {
    viewerId: isAuthenticated
  } = useViewerTenant(true);
  React.useEffect(() => {
    isAuthenticated && signOut("/sign-in-code");
  }, [isAuthenticated]);
  const showAppSidebar = useSetRecoilState(appSidebar);
  React.useLayoutEffect(() => {
    showAppSidebar(false);
  }, [showAppSidebar]);
  useDocumentTitle(t("signIn.pageTitle"));
  const onSubmit = async (values: FormValues) => {
    try {
      setLoading(true);
      const response = await fetch("/unauth/local", {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          code: values.code
        })
      });
      const result = await response.json();
      if (["InvalidCredentials", "InvalidAuthProvider"].includes(result.code)) {
        showErrorMessage(result.code);
      }
      if (result.success) {
        window.location.href = result.redirectUri || "/";
      }
    } catch (err) {
      showErrorMessage("InternalError");
    }
  };
  const showErrorMessage = (code: string) => {
    setError(code);
    setLoading(false);
  };
  return <>
      {!isAuthenticated && <AccountLayout>
          <Stack direction="vertical" fill justify="center" align="center">
            <StackItem width="1/3">
              <Stack direction="vertical">
                <Text variant="headingLg">{t("signIn.label")}</Text>

                {error && <Callout intent="critical" className="mb-md">
                    {t([`form.errors.${error}`, "form.errors.unspecified"])}
                  </Callout>}
                <AnonymousSignInForm onSubmit={onSubmit} loading={loading} />
              </Stack>
            </StackItem>
          </Stack>
        </AccountLayout>}
    </>;
};