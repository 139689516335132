import { RouteProps, Route, Redirect } from "@workflows/runtime-web";
import { useAuth } from "./useAuth";
import React from "react";
export function PublicRoute({
  children,
  ...rest
}: RouteProps) {
  const {
    isAuthenticated
  } = useAuth();
  return <Route {...rest} component={({
    location
  }: any) => !isAuthenticated ? (children as any) : <Redirect to={{
    pathname: "/",
    state: {
      from: location
    }
  }} />} />;
}