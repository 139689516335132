import _AccountSignUpRouteMutation from "./__generated__/AccountSignUpRouteMutation.graphql";
import { graphql, newId, Redirect, useHistory, useMutation } from "@workflows/runtime-web";
import { Callout, Heading, Link, Stack, StackItem, Text, useDocumentTitle, useTenantId } from "@workflows/ui";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { ConfigValueSignup, useConfigValue } from "~/core/configValues";
import { AccountSignUpRouteMutation } from "./__generated__/AccountSignUpRouteMutation.graphql";
import { AccountLayout } from "./AccountLayout";
import { AccountSignUpForm, AccountSignUpFormValues } from "./AccountSignUpForm";
export function AccountSignUpRoute(): JSX.Element {
  const history = useHistory();
  const tenantId = useTenantId();
  const [error, setError] = React.useState("");
  const {
    t
  } = useTranslation("ai.workflows.accounts");
  useDocumentTitle(t("AccountSignUpRouteMutation.pageTitle"));
  const [createUser, isPending] = useMutation<AccountSignUpRouteMutation>(mutation);
  const onSubmit = React.useCallback(({
    firstName,
    lastName,
    email,
    password,
    companyName
  }: AccountSignUpFormValues) => {
    createUser({
      variables: {
        input: {
          clientMutationId: newId(),
          tenantId,
          entity: companyName,
          firstName,
          lastName,
          email,
          password
        }
      },
      onError(error) {
        console.error(error);
        setError(t("AccountSignUpRouteMutation.errors.unspecified"));
      },
      onCompleted(data) {
        if (data.createUser?.errors) {
          const errors = data.createUser.errors;
          let error = "AccountSignUpRouteMutation.errors.unspecified";

          // TODO: This can be simplified. We should think about a general
          // handling of errors in mutations
          // TODO: Increment counter to catch validation problems
          if (errors.find(e => e && e.code === "UserAlreadyExists")) {
            error = "AccountSignUpRouteMutation.errors.userAlreadyExists";
          } else {
            console.error(errors);
          }
          setError(t(error));
        } else {
          history.push("/sign-in");
        }
      }
    });
  }, [createUser, history, t, tenantId]);
  const signupConfig = useConfigValue<ConfigValueSignup>("ai.workflows.tenants.config.signup");
  if (!signupConfig?.enabled) {
    return <Redirect to={"/sign-in"} />;
  }
  return <AccountLayout>
      <Stack direction="vertical" fill justify="center" align="center">
        <StackItem width="1/3">
          <Stack direction="vertical">
            <Heading size="lg">{t("AccountSignUpRouteMutation.title")}</Heading>

            <Text>
              <Trans i18nKey="AccountSignUpRoute.login" t={t}>
                Or
                <Link to="/sign-in" style={{
                textDecoration: "underline"
              }}>
                  log in to an existing account.
                </Link>{" "}
              </Trans>
            </Text>

            {error && <Callout intent="critical" className="mb-md">
                {error}
              </Callout>}

            <AccountSignUpForm onSubmit={onSubmit} isPending={isPending} />
          </Stack>
        </StackItem>
      </Stack>
    </AccountLayout>;
}
const mutation = (_AccountSignUpRouteMutation.hash && _AccountSignUpRouteMutation.hash !== "d7100dc196a8b04e9f5631b9616e28ec" && console.error("The definition of 'AccountSignUpRouteMutation' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _AccountSignUpRouteMutation);