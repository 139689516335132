import _ChooseTenantRouteQuery from "./__generated__/ChooseTenantRouteQuery.graphql";
import { getEdgeNodes, graphql, useHistory, useLazyLoadQuery, useSetRecoilState } from "@workflows/runtime-web";
import { Stack, StackItem } from "@workflows/ui";
import * as React from "react";
import { AccountLayout } from "~/accounts/AccountLayout";
import { ChooseTenantRouteQuery } from "./__generated__/ChooseTenantRouteQuery.graphql";
import { TenantList } from "./TenantList";
import { appSidebar } from "~/core/state";
import { CoreSuspenseFallback } from "~/core/CoreSuspenseFallback";
export const ChooseTenantRoute = (): JSX.Element => {
  const history = useHistory();
  const data = useLazyLoadQuery<ChooseTenantRouteQuery>(ViewerQuery, {});
  const tenants = getEdgeNodes(data.viewer?.tenants || null);
  const showAppSidebar = useSetRecoilState(appSidebar);
  React.useLayoutEffect(() => {
    if (tenants.length === 1) {
      showAppSidebar(true);
      window.location.href = tenants[0].host.baseUrl;
    } else {
      showAppSidebar(false);
    }
  }, [tenants, history, showAppSidebar]);
  return <AccountLayout>
      {tenants.length > 0 ? <Stack direction="vertical" fill justify="center" align="center">
          <StackItem width="1/2" align="center">
            <TenantList />
          </StackItem>
        </Stack> : <CoreSuspenseFallback />}
    </AccountLayout>;
};
const ViewerQuery = (_ChooseTenantRouteQuery.hash && _ChooseTenantRouteQuery.hash !== "852d9e6c3f9d27905d8a1fc5f569c307" && console.error("The definition of 'ChooseTenantRouteQuery' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _ChooseTenantRouteQuery);