import { Button, TextGenericIllustration, NonIdealState, Stack, StackItem } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { endpoints } from "~/client/config";
import { hasValidSessionCookie } from "./auth";
export function CoreDebugRoute(): JSX.Element {
  const {
    t
  } = useTranslation("ai.workflows.core");
  const [isLoading, setLoading] = React.useState(false);
  const info = window.location.hash;
  const collectDebuggingInfo = () => {
    setLoading(true);
    const checks: [key: string, resolver: () => Promise<any>][] = [
    // Current URL
    ["window.location", () => Promise.resolve(window.location.href)],
    // Check if cookies are enabled
    ["navigator.cookieEnabled", () => Promise.resolve(navigator.cookieEnabled)],
    // Check if authenticated
    ["document.authenticated", () => hasValidSessionCookie()],
    // Collect browser info
    ["navigator.userAgent", () => Promise.resolve(navigator.userAgent)],
    // Screen size
    ["window.screen.size", () => Promise.resolve([window.screen.availWidth, window.screen.availHeight])],
    // Browser size
    ["window.size", () => Promise.resolve([window.innerWidth, window.innerHeight])],
    // Language
    ["navigator.language", () => Promise.resolve(navigator.language)],
    // Online status
    ["navigator.onLine", () => Promise.resolve(navigator.onLine)],
    // Simple check if a adblocker is installed
    ["navigator.adBlockEnabled", () => new Promise(resolve => {
      try {
        const ad = document.createElement("div");
        const observer = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting) {
            resolve(false);
            ad.remove();
          } else {
            resolve(true);
            ad.remove();
          }
        });
        ad.className = "ad ads ad-placement";
        ad.style.height = "1px";
        document.body.prepend(ad);
        observer.observe(ad);
        // observer.disconnect();
      } catch (err) {
        resolve(null);
      }
    })],
    // Check if requests work
    ["api.reachable", () => fetch(`${endpoints.api}graphql`, {
      method: "POST",
      body: JSON.stringify({
        query: "viewer { id }"
      })
    }).then(res => res.status)]];
    Promise.all(checks.map(([key, resolver]) => {
      try {
        return resolver().then(value => [key, value]).catch(err => [key, {
          error: err.message
        }]);
      } catch (err) {
        return [key, {
          error: err ? (err as Error).message : null
        }];
      }
    })).then(entries => {
      const data = btoa(JSON.stringify(Object.fromEntries(entries)));
      const body = `${t("CoreDebugRoute.mail.instructions")}\n\n---\n${data}`;
      window.location.href = encodeURI(`mailto:support@smartvokat.com?subject=Debug&body=${body}`);
    });
  };
  return <Stack direction="vertical" fill>
      {info && info.length > 0 ? <StackItem>
          <pre>{JSON.stringify(JSON.parse(atob(info.slice(1))), null, 2)}</pre>
        </StackItem> : <NonIdealState header={<TextGenericIllustration />} description={t("CoreDebugRoute.description")} footer={<Button intent="primary" onClick={collectDebuggingInfo} isLoading={isLoading}>
              {t("CoreDebugRoute.actions.submit")}
            </Button>} />}
    </Stack>;
}