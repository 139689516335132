/**
 * @generated SignedSource<<12f842f4d0bc828fab0bead380f6dda0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ConfirmUserInput = {
  clientMutationId: string;
  secret: string;
};
export type AccountConfirmEmailRouteMutation$variables = {
  input: ConfirmUserInput;
};
export type AccountConfirmEmailRouteMutation$data = {
  readonly confirmUser: {
    readonly errors: ReadonlyArray<{
      readonly code: string | null;
      readonly message: string | null;
      readonly path: ReadonlyArray<string | null> | null;
    } | null> | null;
  } | null;
};
export type AccountConfirmEmailRouteMutation = {
  response: AccountConfirmEmailRouteMutation$data;
  variables: AccountConfirmEmailRouteMutation$variables;
};
const node: ConcreteRequest = function () {
  var v0 = [{
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "input"
    }],
    v1 = [{
      "alias": null,
      "args": [{
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }],
      "concreteType": "ConfirmUserPayload",
      "kind": "LinkedField",
      "name": "confirmUser",
      "plural": false,
      "selections": [{
        "alias": null,
        "args": null,
        "concreteType": "InputError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [{
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }],
        "storageKey": null
      }],
      "storageKey": null
    }];
  return {
    "fragment": {
      "argumentDefinitions": v0 /*: any*/,
      "kind": "Fragment",
      "metadata": null,
      "name": "AccountConfirmEmailRouteMutation",
      "selections": v1 /*: any*/,
      "type": "Mutation",
      "abstractKey": null
    },
    "kind": "Request",
    "operation": {
      "argumentDefinitions": v0 /*: any*/,
      "kind": "Operation",
      "name": "AccountConfirmEmailRouteMutation",
      "selections": v1 /*: any*/
    },

    "params": {
      "cacheID": "d62cd4f34ae94493a59055fcd915eea0",
      "id": null,
      "metadata": {},
      "name": "AccountConfirmEmailRouteMutation",
      "operationKind": "mutation",
      "text": "mutation AccountConfirmEmailRouteMutation(\n  $input: ConfirmUserInput!\n) {\n  confirmUser(input: $input) {\n    errors {\n      code\n      path\n      message\n    }\n  }\n}\n"
    }
  };
}();
(node as any).hash = "342cfa88fd34935556e2da0b4feae413";
export default node;