import i18next from "i18next";
import { ConfigValueLegalAgreements, useConfigValue } from "../../core/configValues";
export const useLegalAgreements = () => {
  const legalAgreementConfig = useConfigValue<ConfigValueLegalAgreements>("ai.workflows.tenants.config.legalAgreements");
  let currentLocale = i18next.language;
  const isLanguageSupported = legalAgreementConfig && Object.keys(legalAgreementConfig).includes(currentLocale);
  if (!isLanguageSupported) {
    currentLocale = "en";
  }
  return legalAgreementConfig?.[currentLocale];
};