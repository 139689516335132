/* eslint-disable node/no-unsupported-features/es-syntax */
import { RouteComponentProps } from "@workflows/runtime-web";
import * as React from "react";
import { apps } from "~/apps";
import { CoreSuspenseFallback } from "../core/CoreSuspenseFallback";
import { CoreError404 } from "~/core/CoreError404";
export interface AppRenderRouteProps extends RouteComponentProps {
  handler: string;
}
export function AppRenderRoute({
  handler,
  ...props
}: AppRenderRouteProps): JSX.Element | null {
  // TODO: Fetch the information about the app from the API (unless it is a
  // built-in app). In the meantime we'll use a simple static mapping.

  if (!apps[handler]) {
    console.error("Missing entry point mapping for route " + handler);
    return <CoreError404 />;
  }
  const AppComponent = React.lazy(() => apps[handler]().then((mod: any) => {
    const [, exportName] = handler.split(":"); // Todo: Remove me when handlers normalized
    return {
      default: mod[exportName || handler]
    };
  }));
  return <React.Suspense fallback={<CoreSuspenseFallback />}>
      <AppComponent {...props} />
    </React.Suspense>;
}