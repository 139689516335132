import * as Sentry from "@sentry/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { config } from "~/client/config";
import { CoreBootloader } from "~/core/CoreBootloader";
import { CoreKernel } from "~/core/CoreKernel";
import "~/core/i18n";
import { environment } from "./graphql";
import "./index.css";
try {
  Sentry.init({
    dsn: config.env.SENTRY_DSN,
    environment: config.env.SENTRY_ENV,
    release: config.env.SENTRY_RELEASE
  });
} catch (error) {
  console.error(error);
}
const root = document.getElementById("root");
const app = <CoreKernel relay={environment}>
    <CoreBootloader />
  </CoreKernel>;
if (!root) {
  throw new Error("Failed to initialize application.");
}
ReactDOM.createRoot(root).render(app);