import _AccountRecoverPasswordRouteMutation from "./__generated__/AccountRecoverPasswordRouteMutation.graphql";
import { graphql, newId, useMutation } from "@workflows/runtime-web";
import { Callout, FormValues, Heading, Stack, StackItem, Text, useDocumentTitle, useTenantId } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { AccountRecoverPasswordRouteMutation } from "./__generated__/AccountRecoverPasswordRouteMutation.graphql";
import { AccountLayout } from "./AccountLayout";
import { AccountRecoverPasswordForm } from "./AccountRecoverPasswordForm";
export function AccountRecoverPasswordRoute(): JSX.Element {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);
  const {
    t
  } = useTranslation("ai.workflows.accounts");
  const tenantId = useTenantId();
  useDocumentTitle(t("AccountRecoverPasswordRoute.title"));
  const [resetUserPassword] = useMutation<AccountRecoverPasswordRouteMutation>(mutation);
  const onSubmitEmail = async (values: FormValues) => {
    try {
      resetUserPassword({
        variables: {
          input: {
            clientMutationId: newId(),
            email: (values.email as string),
            tenantId
          }
        },
        onError(error) {
          console.error(error);
          showErrorMessage("unspecified");
        },
        onCompleted() {
          setCompleted(true);
        }
      });
    } catch (err) {
      console.error(err);
      showErrorMessage("unspecified");
    }
  };
  const showErrorMessage = (code: string) => {
    setError(code);
    setLoading(false);
  };
  return <AccountLayout>
      <Stack direction="vertical" fill justify="center" align="center">
        <StackItem className="w--full" style={{
        maxWidth: "30rem"
      }}>
          <Heading size="lg" className="mb-md">
            {t("AccountRecoverPasswordRoute.title")}
          </Heading>
          {completed ? <Text className="mb-md">
              {t("AccountRecoverPasswordRoute.message.completed")}
            </Text> : <>
              {error && <Callout intent="critical" className="mb-md">
                  {t([`AccountRecoverPasswordRoute.errors.${error}`, "AccountRecoverPasswordRoute.errors.unspecified"])}
                </Callout>}
              <AccountRecoverPasswordForm onSubmit={onSubmitEmail} loading={loading} />
            </>}
        </StackItem>
      </Stack>
    </AccountLayout>;
}
const mutation = (_AccountRecoverPasswordRouteMutation.hash && _AccountRecoverPasswordRouteMutation.hash !== "663a79b9b2f193812a7f0c61663b663b" && console.error("The definition of 'AccountRecoverPasswordRouteMutation' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _AccountRecoverPasswordRouteMutation);