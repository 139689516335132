/**
 * @generated SignedSource<<1a1ea6705f3ff135ada17a76aa5bf447>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateUserInput = {
  clientMutationId: string;
  email: string;
  entity?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password: string;
  roleId?: string | null;
  roleIdentifier?: string | null;
  tenantId: string;
  title?: string | null;
};
export type AccountSignUpRouteMutation$variables = {
  input: CreateUserInput;
};
export type AccountSignUpRouteMutation$data = {
  readonly createUser: {
    readonly errors: ReadonlyArray<{
      readonly code: string | null;
      readonly message: string | null;
      readonly path: ReadonlyArray<string | null> | null;
    } | null> | null;
    readonly user: {
      readonly id: string;
    } | null;
  } | null;
};
export type AccountSignUpRouteMutation = {
  response: AccountSignUpRouteMutation$data;
  variables: AccountSignUpRouteMutation$variables;
};
const node: ConcreteRequest = function () {
  var v0 = [{
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "input"
    }],
    v1 = [{
      "alias": null,
      "args": [{
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }],
      "concreteType": "CreateUserPayload",
      "kind": "LinkedField",
      "name": "createUser",
      "plural": false,
      "selections": [{
        "alias": null,
        "args": null,
        "concreteType": "InputError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [{
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }],
        "storageKey": null
      }, {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [{
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }],
        "storageKey": null
      }],
      "storageKey": null
    }];
  return {
    "fragment": {
      "argumentDefinitions": v0 /*: any*/,
      "kind": "Fragment",
      "metadata": null,
      "name": "AccountSignUpRouteMutation",
      "selections": v1 /*: any*/,
      "type": "Mutation",
      "abstractKey": null
    },
    "kind": "Request",
    "operation": {
      "argumentDefinitions": v0 /*: any*/,
      "kind": "Operation",
      "name": "AccountSignUpRouteMutation",
      "selections": v1 /*: any*/
    },

    "params": {
      "cacheID": "9ab9c5a26950b0f3cbf2e3cc50205d10",
      "id": null,
      "metadata": {},
      "name": "AccountSignUpRouteMutation",
      "operationKind": "mutation",
      "text": "mutation AccountSignUpRouteMutation(\n  $input: CreateUserInput!\n) {\n  createUser(input: $input) {\n    errors {\n      code\n      path\n      message\n    }\n    user {\n      id\n    }\n  }\n}\n"
    }
  };
}();
(node as any).hash = "d7100dc196a8b04e9f5631b9616e28ec";
export default node;