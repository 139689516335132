import * as React from "react";
import { Button, Form2, FormControl, FormInput, FormValues, Stack, TextField, TextFieldProps, useForm } from "@workflows/ui";
import { useTranslation } from "react-i18next";
export interface AccountSetNewPasswordFormProps {
  onSubmit: ({
    password
  }: FormValues) => void;
  loading: boolean;
}
export function AccountSetNewPasswordForm({
  onSubmit,
  loading
}: AccountSetNewPasswordFormProps): JSX.Element {
  const {
    t
  } = useTranslation("ai.workflows.accounts", {
    keyPrefix: "AccountSetNewPasswordForm"
  });
  const form = useForm("SetNewPasswordForm");
  const validatePassword = (password: string) => {
    password = String(password).trim();
    if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/g)) {
      return t("fields.password.required");
    }
    return true;
  };
  const handleSubmit = async () => {
    const values = form.getValues();
    const {
      isValid
    } = await form.validate();
    if (isValid) {
      onSubmit?.(values);
    }
  };
  return <Form2 name={"SetNewPasswordForm"} onSubmit={handleSubmit} defaultValues={{
    password: ""
  }}>
      <Stack direction="vertical" gap="lg">
        <FormControl htmlFor="password" name="password" label={t("fields.password.label")}>
          <FormInput<TextFieldProps> id="password" component={TextField} type="password" name="password" autoFocus validate={validatePassword} required={(t("fields.password.required") as string)} placeholder={t("fields.password.placeholder")} />
        </FormControl>
        <Button intent="primary" onClick={handleSubmit} fill isLoading={loading} type="submit">
          {t("actions.submit")}
        </Button>
      </Stack>
    </Form2>;
}