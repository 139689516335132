import * as React from "react";
import { getEdgeNodes } from "@workflows/runtime-web";
import { Button, Columns, Permissions } from "@workflows/ui";
import { MatterColumnSkeleton } from "~/matters/Matter/MatterColumnSkeleton";
import { ViewerPermission } from "@workflows/business";
import { useMatters } from "~/matters/hooks/useMatters";
import { usePaginatedMatters } from "~/matters/hooks/usePaginatedMatters";
import { useMatter } from "~/matters/Matter/hooks/useMatter";
import { MatterOverviewColumn } from "~/matters/Matter/MatterOverview/MatterOverviewColumn";
import { useTranslation } from "react-i18next";
import { signOut } from "~/core/auth";
import { CorePage } from "~/core/CorePage";
export function AnonymousMatterRoute(): JSX.Element {
  const {
    fragment
  } = useMatters({});
  const {
    data
  } = usePaginatedMatters(fragment);
  const matters = getEdgeNodes(data.matters);
  const [{
    matter
  }] = useMatter(matters[0]?.id);
  const {
    t
  } = useTranslation("ai.workflows.matters", {
    keyPrefix: "AnonymousMatterRoute"
  });
  return <CorePage>
      <Columns>
        <Permissions.Context wrn={matter?.wrn} permissions={(matter?.viewerPermissions as ViewerPermission[])}>
          <React.Suspense fallback={<MatterColumnSkeleton role="main" background="muted" />}>
            {matter && <MatterOverviewColumn matter={matter} actions={<Button intent="primary" onClick={() => signOut("/sign-in-code")}>
                    {t("signOut")}
                  </Button>} />}
          </React.Suspense>
        </Permissions.Context>
      </Columns>
    </CorePage>;
}