import { RouteProps, Route, Redirect } from "@workflows/runtime-web";
import React from "react";
import { useAuth } from "./useAuth";
export function ProtectedRoute({
  children,
  ...rest
}: RouteProps & {
  show?: boolean;
}) {
  const {
    isAuthenticated
  } = useAuth();
  return <Route {...rest} component={({
    location
  }: any) => isAuthenticated ? (children as any) : <Redirect to={{
    pathname: "/sign-in",
    state: {
      from: location
    }
  }} />} />;
}