import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button, Form2, FormControl, FormInput, FormValues, Stack, TextField, TextFieldProps, useForm } from "@workflows/ui";
export interface AccountRecoverPasswordFormProps {
  onSubmit: ({
    email
  }: FormValues) => void;
  loading: boolean;
}
export function AccountRecoverPasswordForm({
  onSubmit,
  loading
}: AccountRecoverPasswordFormProps): JSX.Element {
  const {
    t
  } = useTranslation("ai.workflows.accounts");
  const form = useForm("RecoverPasswordForm");
  const handleSubmit = async () => {
    const values = form.getValues();
    const {
      isValid
    } = await form.validate();
    if (isValid) {
      onSubmit?.(values);
    }
  };
  return <Form2 name={"RecoverPasswordForm"} onSubmit={handleSubmit} defaultValues={{
    email: ""
  }}>
      <Stack direction="vertical" gap="lg">
        <FormControl htmlFor="email" name="email" label={t("AccountRecoverPasswordForm.fields.email.label")}>
          <FormInput<TextFieldProps> id="email" component={TextField} type="email" name="email" autoFocus required={(t("AccountRecoverPasswordForm.fields.email.required") as string)} placeholder={t("AccountRecoverPasswordForm.fields.email.placeholder")} />
        </FormControl>
        <Button intent="primary" onClick={handleSubmit} fill isLoading={loading} type="submit">
          {t("AccountRecoverPasswordForm.actions.submit")}
        </Button>
      </Stack>
    </Form2>;
}