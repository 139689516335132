/**
 * @generated SignedSource<<7686157abc29dca22236977cc5e1399c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ChooseTenantRouteQuery$variables = {};
export type ChooseTenantRouteQuery$data = {
  readonly viewer: {
    readonly tenants: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly host: {
            readonly baseUrl: string;
          };
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type ChooseTenantRouteQuery = {
  response: ChooseTenantRouteQuery$data;
  variables: ChooseTenantRouteQuery$variables;
};
const node: ConcreteRequest = function () {
  var v0 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baseUrl",
      "storageKey": null
    },
    v1 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    };
  return {
    "fragment": {
      "argumentDefinitions": [],
      "kind": "Fragment",
      "metadata": null,
      "name": "ChooseTenantRouteQuery",
      "selections": [{
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [{
          "alias": null,
          "args": null,
          "concreteType": "TenantConnection",
          "kind": "LinkedField",
          "name": "tenants",
          "plural": false,
          "selections": [{
            "alias": null,
            "args": null,
            "concreteType": "TenantEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [{
              "alias": null,
              "args": null,
              "concreteType": "Tenant",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [{
                "alias": null,
                "args": null,
                "concreteType": "Host",
                "kind": "LinkedField",
                "name": "host",
                "plural": false,
                "selections": [v0 /*: any*/],

                "storageKey": null
              }],
              "storageKey": null
            }],
            "storageKey": null
          }],
          "storageKey": null
        }],
        "storageKey": null
      }],
      "type": "Query",
      "abstractKey": null
    },
    "kind": "Request",
    "operation": {
      "argumentDefinitions": [],
      "kind": "Operation",
      "name": "ChooseTenantRouteQuery",
      "selections": [{
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [{
          "alias": null,
          "args": null,
          "concreteType": "TenantConnection",
          "kind": "LinkedField",
          "name": "tenants",
          "plural": false,
          "selections": [{
            "alias": null,
            "args": null,
            "concreteType": "TenantEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [{
              "alias": null,
              "args": null,
              "concreteType": "Tenant",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [{
                "alias": null,
                "args": null,
                "concreteType": "Host",
                "kind": "LinkedField",
                "name": "host",
                "plural": false,
                "selections": [v0 /*: any*/, v1 /*: any*/],

                "storageKey": null
              }, v1 /*: any*/],

              "storageKey": null
            }],
            "storageKey": null
          }],
          "storageKey": null
        }, v1 /*: any*/],

        "storageKey": null
      }]
    },
    "params": {
      "cacheID": "5e2e8a1f10a77e07720bcaad83becf4b",
      "id": null,
      "metadata": {},
      "name": "ChooseTenantRouteQuery",
      "operationKind": "query",
      "text": "query ChooseTenantRouteQuery {\n  viewer {\n    tenants {\n      edges {\n        node {\n          host {\n            baseUrl\n            id\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
    }
  };
}();
(node as any).hash = "852d9e6c3f9d27905d8a1fc5f569c307";
export default node;