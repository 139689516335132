import { Dialog, DialogBody, DialogProps } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { CoreErrorBoundary } from "~/core/CoreErrorBoundary";
import { AccountTenantSwitcher } from "./AccountTenantSwitcher";
export interface AccountTenantSwitcherDialogProps extends Omit<DialogProps, "children" | "title"> {
  children?: React.ReactNode;
}
export function AccountTenantSwitcherDialog({
  ...props
}: AccountTenantSwitcherDialogProps): JSX.Element {
  const {
    t
  } = useTranslation("ai.workflows.accounts");
  return <Dialog {...props} title={t("AccountTenantSwitcherDialog.title")} size="md">
      <DialogBody>
        <CoreErrorBoundary>
          <React.Suspense fallback={<AccountTenantSwitcher.Skeleton />}>
            <AccountTenantSwitcher />
          </React.Suspense>
        </CoreErrorBoundary>
      </DialogBody>
    </Dialog>;
}