/**
 * @generated SignedSource<<4d3296ede081d9cc67a5e4bed122c55e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserPasswordInput = {
  clientMutationId: string;
  password: string;
  secret: string;
};
export type AccountSetNewPasswordRouteMutation$variables = {
  input: UpdateUserPasswordInput;
};
export type AccountSetNewPasswordRouteMutation$data = {
  readonly updateUserPassword: {
    readonly clientMutationId: string;
    readonly errors: ReadonlyArray<{
      readonly code: string | null;
      readonly message: string | null;
      readonly path: ReadonlyArray<string | null> | null;
      readonly type: string | null;
    } | null> | null;
  } | null;
};
export type AccountSetNewPasswordRouteMutation = {
  response: AccountSetNewPasswordRouteMutation$data;
  variables: AccountSetNewPasswordRouteMutation$variables;
};
const node: ConcreteRequest = function () {
  var v0 = [{
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "input"
    }],
    v1 = [{
      "alias": null,
      "args": [{
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }],
      "concreteType": "UpdateUserPasswordPayload",
      "kind": "LinkedField",
      "name": "updateUserPassword",
      "plural": false,
      "selections": [{
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }, {
        "alias": null,
        "args": null,
        "concreteType": "InputError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [{
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }],
        "storageKey": null
      }],
      "storageKey": null
    }];
  return {
    "fragment": {
      "argumentDefinitions": v0 /*: any*/,
      "kind": "Fragment",
      "metadata": null,
      "name": "AccountSetNewPasswordRouteMutation",
      "selections": v1 /*: any*/,
      "type": "Mutation",
      "abstractKey": null
    },
    "kind": "Request",
    "operation": {
      "argumentDefinitions": v0 /*: any*/,
      "kind": "Operation",
      "name": "AccountSetNewPasswordRouteMutation",
      "selections": v1 /*: any*/
    },

    "params": {
      "cacheID": "206c076b1a78358970ed96486b4515b8",
      "id": null,
      "metadata": {},
      "name": "AccountSetNewPasswordRouteMutation",
      "operationKind": "mutation",
      "text": "mutation AccountSetNewPasswordRouteMutation(\n  $input: UpdateUserPasswordInput!\n) {\n  updateUserPassword(input: $input) {\n    clientMutationId\n    errors {\n      code\n      path\n      message\n      type\n    }\n  }\n}\n"
    }
  };
}();
(node as any).hash = "31fc36d3493e3d381ada6cc41b211177";
export default node;