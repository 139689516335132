import _CoreNav_nav from "./__generated__/CoreNav_nav.graphql";
import { ExtractNode, getEdgeNodes, graphql, toTrees, useFragment } from "@workflows/runtime-web";
import { icons, MenuSize, Nav as BaseNav } from "@workflows/ui";
import * as React from "react";
import { CoreNav_nav$data, CoreNav_nav$key } from "./__generated__/CoreNav_nav.graphql";
export interface CoreNavProps {
  nav: CoreNav_nav$key;
  size?: MenuSize;
  onClick: () => void;
}
export function CoreNav(props: CoreNavProps): JSX.Element {
  const {
    nav
  } = useFragment(CoreNav_nav, props.nav);
  const edges = getEdgeNodes(nav?.items || null);
  const trees = toTrees(edges);

  // TODO: This is a hotfix for filtering the nav items based on the permissions
  // of the tenant.
  // const mapping: Record<string, string[]> = {
  //   "ai.workflows.navs.item.admin:users": ["ai.workflows.users:list"],
  // };

  return <BaseNav size={props.size || "xs"}>
      {trees.map(nav => {
      // if (
      //   nav.identifier &&
      //   Object.prototype.hasOwnProperty.call(mapping, nav.identifier) &&
      //   !mapping[nav.identifier].some((p) => viewerPermissions.includes(p))
      // ) {
      //   return;
      // }
      if (nav.route || nav.externalUrl) {
        return <CoreNavItem item={nav} key={nav.id} onClick={props.onClick} />;
      }
      return <BaseNav.Section key={nav.id} label={nav.name}>
            {(nav.props?.children || []).map(item => <CoreNavItem item={item} key={item.id} onClick={props.onClick} />)}
          </BaseNav.Section>;
    })}
    </BaseNav>;
}
export interface CoreNavItemProps {
  item: ExtractNode<NonNullable<NonNullable<CoreNav_nav$data["nav"]>["items"]>>;
  onClick: () => void;
}
const CoreNavItem = ({
  item,
  onClick
}: CoreNavItemProps) => {
  const icon = item.icon && icons[item.icon] ? React.createElement(icons[item.icon]) : null;
  if (item.externalUrl) {
    return <BaseNav.Item key={item.id} href={item.externalUrl} label={item.name} icon={icon} onClick={onClick} />;
  }
  return <BaseNav.Item key={item.id} to={item.route ? item.route.path : ""} label={item.name} icon={icon} exact={!item.route || item.route.path.length < 2} onClick={onClick} />;
};

// TODO: Handle pagination
const CoreNav_nav = (_CoreNav_nav.hash && _CoreNav_nav.hash !== "549c47022e3bbd90380af5d913950633" && console.error("The definition of 'CoreNav_nav' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _CoreNav_nav);