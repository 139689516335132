import { Theme, ThemeContext } from "@workflows/ui";
import * as React from "react";
import { config } from "~/client/config";
export interface CoreThemeProps {
  children?: React.ReactNode;
}
export function CoreTheme({
  children
}: CoreThemeProps): JSX.Element {
  const currentTheme = React.useMemo<Theme>(() => config.theme || {
    logo: {
      lg: "/logo.svg",
      xs: "/logo.svg"
    },
    favicon: "/favicon.ico"
  }, []);
  return <ThemeContext theme={currentTheme}>{children}</ThemeContext>;
}