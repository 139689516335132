/**
 * @generated SignedSource<<8eb80c2c52856838aaa19f052904599f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type _FilterOp = "EQUAL" | "IN" | "IS_SET" | "LIKE" | "%future added value";
export type _SortOrder = "ASC" | "DESC" | "%future added value";
export type _FilterInput = {
  and?: ReadonlyArray<_FilterInput> | null;
  field?: string | null;
  label?: string | null;
  op?: _FilterOp | null;
  or?: ReadonlyArray<_FilterInput> | null;
  value?: any | null;
};
export type _SortInput = {
  field: string;
  order: _SortOrder;
};
export type useMattersQuery$variables = {
  after?: string | null;
  filter?: _FilterInput | null;
  first?: number | null;
  sort?: ReadonlyArray<_SortInput> | null;
  tenantId: string;
};
export type useMattersQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"usePaginatedMatters_matters">;
};
export type useMattersQuery = {
  response: useMattersQuery$data;
  variables: useMattersQuery$variables;
};
const node: ConcreteRequest = function () {
  var v0 = {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    v1 = {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    },
    v2 = {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    v3 = {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    },
    v4 = {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tenantId"
    },
    v5 = [{
      "kind": "Variable",
      "name": "after",
      "variableName": "after"
    }, {
      "kind": "Variable",
      "name": "filter",
      "variableName": "filter"
    }, {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    }, {
      "kind": "Variable",
      "name": "sort",
      "variableName": "sort"
    }, {
      "kind": "Variable",
      "name": "tenantId",
      "variableName": "tenantId"
    }],
    v6 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cursor",
      "storageKey": null
    },
    v7 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    v8 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "titleText",
      "storageKey": null
    },
    v9 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    v10 = [{
      "kind": "Literal",
      "name": "first",
      "value": 100
    }],
    v11 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    v12 = {
      "alias": null,
      "args": null,
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [{
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      }, {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }],
      "storageKey": null
    },
    v13 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textColor",
      "storageKey": null
    },
    v14 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    };
  return {
    "fragment": {
      "argumentDefinitions": [v0 /*: any*/, v1 /*: any*/, v2 /*: any*/, v3 /*: any*/, v4 /*: any*/],

      "kind": "Fragment",
      "metadata": null,
      "name": "useMattersQuery",
      "selections": [{
        "args": v5 /*: any*/,
        "kind": "FragmentSpread",
        "name": "usePaginatedMatters_matters"
      }],
      "type": "Query",
      "abstractKey": null
    },
    "kind": "Request",
    "operation": {
      "argumentDefinitions": [v4 /*: any*/, v1 /*: any*/, v3 /*: any*/, v0 /*: any*/, v2 /*: any*/],

      "kind": "Operation",
      "name": "useMattersQuery",
      "selections": [{
        "alias": "matters",
        "args": v5 /*: any*/,
        "concreteType": "MatterConnectionType",
        "kind": "LinkedField",
        "name": "_matters",
        "plural": false,
        "selections": [{
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "concreteType": "MatterConnectionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [v6 /*: any*/, {
            "alias": null,
            "args": null,
            "concreteType": "_Matter",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [v7 /*: any*/, v8 /*: any*/, {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            }, {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dueDate",
              "storageKey": null
            }, {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "insertedAt",
              "storageKey": null
            }, {
              "alias": null,
              "args": null,
              "concreteType": "_User",
              "kind": "LinkedField",
              "name": "creator",
              "plural": false,
              "selections": [v7 /*: any*/, v9 /*: any*/],

              "storageKey": null
            }, {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "externalReference",
              "storageKey": null
            }, {
              "kind": "InlineFragment",
              "selections": [{
                "alias": "assignees",
                "args": v10 /*: any*/,
                "concreteType": "_AssigneeConnection",
                "kind": "LinkedField",
                "name": "_assignees",
                "plural": false,
                "selections": [{
                  "alias": null,
                  "args": null,
                  "concreteType": "_AssigneeConnectionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [{
                    "alias": null,
                    "args": null,
                    "concreteType": "_Assignee",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [v7 /*: any*/, {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "actor",
                      "plural": false,
                      "selections": [v11 /*: any*/, v7 /*: any*/, v9 /*: any*/],

                      "storageKey": null
                    }, v11 /*: any*/],

                    "storageKey": null
                  }, v6 /*: any*/],

                  "storageKey": null
                }, v12 /*: any*/],

                "storageKey": "_assignees(first:100)"
              }, {
                "alias": "assignees",
                "args": v10 /*: any*/,
                "filters": null,
                "handle": "connection",
                "key": "AssigneesPreview__assignees",
                "kind": "LinkedHandle",
                "name": "_assignees"
              }],
              "type": "_HasAssignees",
              "abstractKey": "__is_HasAssignees"
            }, {
              "kind": "InlineFragment",
              "selections": [{
                "alias": "status",
                "args": null,
                "concreteType": "_Status",
                "kind": "LinkedField",
                "name": "_status",
                "plural": false,
                "selections": [v7 /*: any*/, {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "identifier",
                  "storageKey": null
                }, v9 /*: any*/, v13 /*: any*/, v14 /*: any*/],

                "storageKey": null
              }],
              "type": "_HasStatus",
              "abstractKey": "__is_HasStatus"
            }, {
              "kind": "InlineFragment",
              "selections": [{
                "alias": null,
                "args": null,
                "concreteType": "_TagConnection",
                "kind": "LinkedField",
                "name": "tags",
                "plural": false,
                "selections": [{
                  "alias": null,
                  "args": null,
                  "concreteType": "_TagEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [{
                    "alias": null,
                    "args": null,
                    "concreteType": "_Tag",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [v7 /*: any*/, v14 /*: any*/, v8 /*: any*/, v13 /*: any*/],

                    "storageKey": null
                  }],
                  "storageKey": null
                }],
                "storageKey": null
              }],
              "type": "_HasTags",
              "abstractKey": "__is_HasTags"
            }, v11 /*: any*/],

            "storageKey": null
          }],
          "storageKey": null
        }, v12 /*: any*/],

        "storageKey": null
      }, {
        "alias": "matters",
        "args": v5 /*: any*/,
        "filters": ["tenantId", "filter", "page", "limit", "sort"],
        "handle": "connection",
        "key": "usePaginatedMatters_matters",
        "kind": "LinkedHandle",
        "name": "_matters"
      }]
    },
    "params": {
      "cacheID": "6faaf86307861f03ffeebee993e0e14a",
      "id": null,
      "metadata": {},
      "name": "useMattersQuery",
      "operationKind": "query",
      "text": "query useMattersQuery(\n  $tenantId: ID!\n  $filter: _FilterInput\n  $sort: [_SortInput!]\n  $after: String\n  $first: Int\n) {\n  ...usePaginatedMatters_matters_H52bF\n}\n\nfragment AssigneesPreview__node on _HasAssignees {\n  __is_HasAssignees: __typename\n  assignees: _assignees(first: 100) {\n    edges {\n      node {\n        id\n        actor {\n          __typename\n          id\n          name\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment Status__status on _HasStatus {\n  __is_HasStatus: __typename\n  status: _status {\n    id\n    identifier\n    name\n    textColor\n    color\n  }\n}\n\nfragment Tags_tags on _HasTags {\n  __is_HasTags: __typename\n  tags {\n    edges {\n      node {\n        id\n        color\n        titleText\n        textColor\n      }\n    }\n  }\n}\n\nfragment usePaginatedMatters_matters_H52bF on Query {\n  matters: _matters(tenantId: $tenantId, first: $first, after: $after, filter: $filter, sort: $sort) {\n    total\n    edges {\n      cursor\n      node {\n        id\n        titleText\n        number\n        dueDate\n        insertedAt\n        creator {\n          id\n          name\n        }\n        externalReference\n        ...AssigneesPreview__node\n        ...Status__status\n        ...Tags_tags\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
    }
  };
}();
(node as any).hash = "50b83d52147dcb4ec4bc0105b3efadde";
export default node;