/**
 * @generated SignedSource<<dcea174872ef6598c331969f5ad183dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoreApp__tenant$data = {
  readonly permissions: ReadonlyArray<{
    readonly identifier: string;
    readonly refId: string;
    readonly type: string;
  }>;
  readonly wrn: string;
  readonly " $fragmentType": "CoreApp__tenant";
};
export type CoreApp__tenant$key = {
  readonly " $data"?: CoreApp__tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoreApp__tenant">;
};
const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoreApp__tenant",
  "selections": [{
    "alias": null,
    "args": null,
    "concreteType": "Permission",
    "kind": "LinkedField",
    "name": "permissions",
    "plural": true,
    "selections": [{
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refId",
      "storageKey": null
    }, {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }, {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }],
    "storageKey": null
  }, {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "wrn",
    "storageKey": null
  }],
  "type": "_Tenant",
  "abstractKey": null
};
(node as any).hash = "f3acf7f79d25704c94345bc90ecd93ab";
export default node;