import _AccountTenantSwitcher_tenants from "./__generated__/AccountTenantSwitcher_tenants.graphql";
import _AccountTenantSwitcherQuery from "./__generated__/AccountTenantSwitcherQuery.graphql";
import { ExtractNode, getEdgeNodes, graphql, useFragment, useLazyLoadQuery } from "@workflows/runtime-web";
import { Link, ListViewCore, Skeleton, useViewerTenant } from "@workflows/ui";
import * as React from "react";
import { CoreError500 } from "~/core/CoreError500";
import { AccountTenantSwitcherQuery } from "./__generated__/AccountTenantSwitcherQuery.graphql";
import { AccountTenantSwitcher_tenants$data, AccountTenantSwitcher_tenants$key } from "./__generated__/AccountTenantSwitcher_tenants.graphql";
export function AccountTenantSwitcher(): JSX.Element {
  const data = useLazyLoadQuery<AccountTenantSwitcherQuery>($AccountTenantSwitcherQuery, {});
  if (!data.viewer) {
    return <CoreError500 />;
  }
  return <React.Suspense fallback={<AccountTenantSwitcherListSkeleton />}>
      <AccountTenantSwitcherList fragmentRef={data.viewer} />
    </React.Suspense>;
}
function AccountTenantSwitcherListSkeleton() {
  return <ListViewCore items={[{
    id: "1"
  }, {
    id: "2"
  }]} component={() => <Skeleton width={50} />} bordered />;
}
AccountTenantSwitcher.Skeleton = AccountTenantSwitcherListSkeleton;
function AccountTenantSwitcherList({
  fragmentRef
}: {
  fragmentRef: AccountTenantSwitcher_tenants$key;
}) {
  const {
    tenantId
  } = useViewerTenant(true);

  // TODO: As soon as we drop the federated GraphQL schema we should move to
  // `usePaginationFragement` to enable pagination.
  // const tenants = usePaginationFragment(
  //   $AccountTenantSwitcher_tenants,
  //   fragmentRef
  // );
  const data = useFragment($AccountTenantSwitcher_tenants, fragmentRef);
  const tenants = getEdgeNodes(data.tenants).filter(t => t.id !== tenantId);
  return <ListViewCore items={tenants} interactive={true} component={AccountTenantSwitcherListItem} scrollable={false} bordered />;
}
function AccountTenantSwitcherListItem({
  item
}: {
  item: ExtractNode<NonNullable<AccountTenantSwitcher_tenants$data["tenants"]>>;
}) {
  return <Link href={item.baseUrl}>{item.name}</Link>;
}
const $AccountTenantSwitcherQuery = (_AccountTenantSwitcherQuery.hash && _AccountTenantSwitcherQuery.hash !== "c5f4bb23836bf45dcbe9b818a0a07cf2" && console.error("The definition of 'AccountTenantSwitcherQuery' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _AccountTenantSwitcherQuery);
const $AccountTenantSwitcher_tenants = (_AccountTenantSwitcher_tenants.hash && _AccountTenantSwitcher_tenants.hash !== "f91bab42e76550badd3495c1d5859dc1" && console.error("The definition of 'AccountTenantSwitcher_tenants' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _AccountTenantSwitcher_tenants);