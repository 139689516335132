/**
 * @generated SignedSource<<4bdc9fa35bc350609c9be424d5342ba0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TenantList_Tenant$data = {
  readonly host: {
    readonly baseUrl: string;
    readonly id: string;
  };
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "TenantList_Tenant";
};
export type TenantList_Tenant$key = {
  readonly " $data"?: TenantList_Tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"TenantList_Tenant">;
};
const node: ReaderFragment = function () {
  var v0 = {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  };
  return {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TenantList_Tenant",
    "selections": [v0 /*: any*/, {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }, {
      "alias": null,
      "args": null,
      "concreteType": "Host",
      "kind": "LinkedField",
      "name": "host",
      "plural": false,
      "selections": [v0 /*: any*/, {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baseUrl",
        "storageKey": null
      }],
      "storageKey": null
    }],
    "type": "Tenant",
    "abstractKey": null
  };
}();
(node as any).hash = "66460c4a1e77760431686d4e5ff9224f";
export default node;