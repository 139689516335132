import _ActionMagicLinkMutation from "./__generated__/ActionMagicLinkMutation.graphql";
import * as React from "react";
import { graphql, newId, useMutation } from "@workflows/runtime-web";
import { useTenantId } from "@workflows/ui";
import { ActionMagicLinkMutation } from "./__generated__/ActionMagicLinkMutation.graphql";
import { CoreError404 } from "~/core/CoreError404";
import { setSessionCookie } from "~/core/auth";
interface ActionMagicLinkProps {
  secret: string;
  to: string;
}
export function ActionMagicLink({
  secret,
  to
}: ActionMagicLinkProps): JSX.Element | null {
  const tenantId = useTenantId();
  const [actionError, setActionError] = React.useState(false);
  const [executeAction] = useMutation<ActionMagicLinkMutation>(actionMagicLinkMutation);
  React.useEffect(() => {
    executeAction({
      variables: {
        input: {
          clientMutationId: newId(),
          tenantId,
          secret
        }
      },
      onCompleted(data) {
        if (data.executeAction?.action?.payload.token) {
          setSessionCookie(data.executeAction?.action?.payload.token);
          window.location.href = to;
        } else {
          setActionError(true);
        }
      },
      onError() {
        setActionError(true);
      }
    });
  }, [executeAction, secret, tenantId, to]);
  if (actionError) {
    return <CoreError404 />;
  }
  return null;
}
export const actionMagicLinkMutation = (_ActionMagicLinkMutation.hash && _ActionMagicLinkMutation.hash !== "60bfadad8a8f2a565072994e0c10bd28" && console.error("The definition of 'ActionMagicLinkMutation' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _ActionMagicLinkMutation);