import { Link } from "@workflows/ui";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { ConfigValueLegalAgreements } from "../core/configValues";
type LegalAgreementsLinks = ConfigValueLegalAgreements["en"];
type DisclaimerProps = {
  legalAgreements: LegalAgreementsLinks;
};
export const getTranslationComponent = (legalAgreements: LegalAgreementsLinks) => {
  if (legalAgreements?.dataPrivacy && legalAgreements?.termsOfService) {
    return <DisclaimerTOSandPrivacy legalAgreements={legalAgreements} />;
  }
  if (legalAgreements.dataPrivacy) {
    return <DisclaimerPrivacy legalAgreements={legalAgreements} />;
  }
  if (legalAgreements.termsOfService) {
    return <DisclaimerTOS legalAgreements={legalAgreements} />;
  } else {
    return null;
  }
};
const DisclaimerTOS = ({
  legalAgreements
}: DisclaimerProps) => {
  const {
    t
  } = useTranslation("ai.workflows.accounts");
  return <Trans i18nKey="AccountSignUpForm.disclaimer.tos" t={t}>
      I accept the{" "}
      <Link href={legalAgreements.termsOfService} target="_blank" underline>
        Terms of Service.
      </Link>{" "}
    </Trans>;
};
const DisclaimerPrivacy = ({
  legalAgreements
}: DisclaimerProps) => {
  const {
    t
  } = useTranslation("ai.workflows.accounts");
  return <Trans i18nKey="AccountSignUpForm.disclaimer.privacy" t={t}>
      I accept the{" "}
      <Link href={legalAgreements.dataPrivacy} target="_blank" underline>
        Privacy Policy.
      </Link>{" "}
    </Trans>;
};
const DisclaimerTOSandPrivacy = ({
  legalAgreements
}: DisclaimerProps) => {
  const {
    t
  } = useTranslation("ai.workflows.accounts");
  return <Trans i18nKey="AccountSignUpForm.disclaimer.both" t={t}>
      I accept the{" "}
      <Link href={legalAgreements.termsOfService} target="_blank" underline>
        Terms of Service
      </Link>{" "}
      and the{" "}
      <Link href={legalAgreements.dataPrivacy} target="_blank" underline>
        Privacy Policy.
      </Link>
    </Trans>;
};