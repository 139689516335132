import _ActionRouteQuery from "./__generated__/ActionRouteQuery.graphql";
import { graphql, RouteComponentProps, useLazyLoadQuery } from "@workflows/runtime-web";
import { useTenantId } from "@workflows/ui";
import * as React from "react";
import { ActionMagicLink } from "~/actions/ActionMagicLink";
import { ActionNotFoundState } from "~/actions/ActionNotFoundState";
import { ActionRouteQuery } from "./__generated__/ActionRouteQuery.graphql";
import { ActionRedirect } from "./ActionRedirect";
export type ActionRouteProps = RouteComponentProps<{
  actionId: string;
}>;
export const ActionRoute = ({
  match
}: ActionRouteProps): JSX.Element | null => {
  const secret = match.params.actionId;
  const tenantId = useTenantId();
  const data = useLazyLoadQuery<ActionRouteQuery>($ActionRouteQuery, {
    tenantId,
    secret
  });
  const actionTypeDirectory = React.useMemo(() => {
    return new Map<string, JSX.Element>([["ai.workflows.users.actions.resetPassword", <ActionRedirect to="/reset-password" secret={secret} />], ["ai.workflows.users.actions.setPassword", <ActionRedirect to="/set-password" secret={secret} />], ["ai.workflows.users.actions.confirmEmail", <ActionRedirect to="/confirm-email" secret={secret} />], ["ai.workflows.users.actions.magicLink", <ActionMagicLink to="/choose-tenant" secret={secret} />], ["ai.workflows.users.actions.inviteUser", <ActionRedirect to="/accept-invite" secret={secret} />]]);
  }, [secret]);
  const isValidAction = data.action && data.action.type && actionTypeDirectory.has(data.action.type.identifier);
  return isValidAction ? (actionTypeDirectory.get(data.action.type.identifier) as JSX.Element) : <ActionNotFoundState />;
};
const $ActionRouteQuery = (_ActionRouteQuery.hash && _ActionRouteQuery.hash !== "f86efd3260856a101a3a08081720c5e5" && console.error("The definition of 'ActionRouteQuery' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _ActionRouteQuery);