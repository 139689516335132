/**
 * @generated SignedSource<<1453cdcc55feed0f7af78c95558b46df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoreNav_nav$data = {
  readonly nav: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly externalUrl: string | null;
          readonly icon: string | null;
          readonly id: string;
          readonly identifier: string | null;
          readonly name: string;
          readonly path: ReadonlyArray<string>;
          readonly route: {
            readonly path: string;
          } | null;
          readonly sort: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
  readonly viewerPermissions: ReadonlyArray<string>;
  readonly " $fragmentType": "CoreNav_nav";
};
export type CoreNav_nav$key = {
  readonly " $data"?: CoreNav_nav$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoreNav_nav">;
};
const node: ReaderFragment = function () {
  var v0 = {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "path",
    "storageKey": null
  };
  return {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CoreNav_nav",
    "selections": [{
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerPermissions",
      "storageKey": null
    }, {
      "alias": null,
      "args": [{
        "kind": "Literal",
        "name": "identifier",
        "value": "ai.workflows.nav.main"
      }],
      "concreteType": "Nav",
      "kind": "LinkedField",
      "name": "nav",
      "plural": false,
      "selections": [{
        "alias": null,
        "args": null,
        "concreteType": "NavItemConnection",
        "kind": "LinkedField",
        "name": "items",
        "plural": false,
        "selections": [{
          "alias": null,
          "args": null,
          "concreteType": "NavItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [{
            "alias": null,
            "args": null,
            "concreteType": "NavItem",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [{
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }, {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }, {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sort",
              "storageKey": null
            }, v0 /*: any*/, {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "icon",
              "storageKey": null
            }, {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "identifier",
              "storageKey": null
            }, {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "externalUrl",
              "storageKey": null
            }, {
              "alias": null,
              "args": null,
              "concreteType": "Route",
              "kind": "LinkedField",
              "name": "route",
              "plural": false,
              "selections": [v0 /*: any*/],

              "storageKey": null
            }],
            "storageKey": null
          }],
          "storageKey": null
        }],
        "storageKey": null
      }],
      "storageKey": "nav(identifier:\"ai.workflows.nav.main\")"
    }],
    "type": "Tenant",
    "abstractKey": null
  };
}();
(node as any).hash = "549c47022e3bbd90380af5d913950633";
export default node;