/**
 * @generated SignedSource<<401906b199ca14e02265a532144a3897>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TenantListQuery$variables = {
  count?: number | null;
  cursor?: string | null;
};
export type TenantListQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"TenantList_Tenants">;
  } | null;
};
export type TenantListQuery = {
  response: TenantListQuery$data;
  variables: TenantListQuery$variables;
};
const node: ConcreteRequest = function () {
  var v0 = [{
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    }, {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }],
    v1 = [{
      "kind": "Variable",
      "name": "after",
      "variableName": "cursor"
    }, {
      "kind": "Variable",
      "name": "first",
      "variableName": "count"
    }],
    v2 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    };
  return {
    "fragment": {
      "argumentDefinitions": v0 /*: any*/,
      "kind": "Fragment",
      "metadata": null,
      "name": "TenantListQuery",
      "selections": [{
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [{
          "args": [{
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          }, {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          }],
          "kind": "FragmentSpread",
          "name": "TenantList_Tenants"
        }],
        "storageKey": null
      }],
      "type": "Query",
      "abstractKey": null
    },
    "kind": "Request",
    "operation": {
      "argumentDefinitions": v0 /*: any*/,
      "kind": "Operation",
      "name": "TenantListQuery",
      "selections": [{
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [{
          "alias": null,
          "args": v1 /*: any*/,
          "concreteType": "TenantConnection",
          "kind": "LinkedField",
          "name": "tenants",
          "plural": false,
          "selections": [{
            "alias": null,
            "args": null,
            "concreteType": "TenantEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [{
              "alias": null,
              "args": null,
              "concreteType": "Tenant",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [v2 /*: any*/, {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }, {
                "alias": null,
                "args": null,
                "concreteType": "Host",
                "kind": "LinkedField",
                "name": "host",
                "plural": false,
                "selections": [v2 /*: any*/, {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "baseUrl",
                  "storageKey": null
                }],
                "storageKey": null
              }, {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              }],
              "storageKey": null
            }, {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }],
            "storageKey": null
          }, {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [{
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }, {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }],
            "storageKey": null
          }],
          "storageKey": null
        }, {
          "alias": null,
          "args": v1 /*: any*/,
          "filters": null,
          "handle": "connection",
          "key": "TenantList_tenants",
          "kind": "LinkedHandle",
          "name": "tenants"
        }, v2 /*: any*/],

        "storageKey": null
      }]
    },
    "params": {
      "cacheID": "8fe4b80e17543105a9b279a52e900d8a",
      "id": null,
      "metadata": {},
      "name": "TenantListQuery",
      "operationKind": "query",
      "text": "query TenantListQuery(\n  $count: Int\n  $cursor: String\n) {\n  viewer {\n    ...TenantList_Tenants_1G22uz\n    id\n  }\n}\n\nfragment TenantList_Tenant on Tenant {\n  id\n  name\n  host {\n    id\n    baseUrl\n  }\n}\n\nfragment TenantList_Tenants_1G22uz on Viewer {\n  tenants(first: $count, after: $cursor) {\n    edges {\n      node {\n        ...TenantList_Tenant\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
    }
  };
}();
(node as any).hash = "d09dbbd1606e6dcbb41dc3ea4ca0a555";
export default node;