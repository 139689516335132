import * as React from "react";

// TODO: Move this hook to @workflows/runtime-web
export function useUrlSearchParams<T = Record<string, string | number>>(defaultValues: Partial<T>): [T, (newParams: Record<string, string | number>) => void] {
  const [state] = React.useState<T>(() => {
    const query = getUrlParams();
    const params = Object.create({});
    Object.entries(defaultValues).forEach(([key, value]) => {
      params[key] = value;
    });
    query.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  });

  // const update = React.useCallback(
  //   (newState: Record<string, string | number>) => {
  //     const query = getUrlParams();

  //     Object.entries(newState).forEach(([key, value]) => {
  //       query.set(key, String(value));
  //     });

  //     setState(newState as unknown as T);
  //   },
  //   [setState]
  // );

  const update = React.useCallback((_newState: Record<string, string | number>) => {
    throw new Error("Not implemented yet.");
  }, []);
  return [state, update];
}
function getUrlParams(): URLSearchParams {
  if (typeof URLSearchParams == "undefined") {
    throw new Error(`Class "URLSearchParams" not found.`);
  }
  if (typeof window !== "undefined") {
    return new URLSearchParams(window.location.search);
  }
  return new URLSearchParams();
}