import _CoreApp_tenant from "./__generated__/CoreApp_tenant.graphql";
import _CoreApp__tenant from "./__generated__/CoreApp__tenant.graphql";
import _CoreApp_viewer from "./__generated__/CoreApp_viewer.graphql";
import _CoreApp__viewer from "./__generated__/CoreApp__viewer.graphql";
import { classNames, ExtractNode, getEdgeNodes, graphql, useFragment, useRecoilValue } from "@workflows/runtime-web";
import { ErrorProvider, MenuSize, Permissions, Stack, useBreakpointMatch, ViewerTenantContext } from "@workflows/ui";
import i18next from "i18next";
import * as React from "react";
import { ConfigValueLanguage } from "~/core/configValues";
import { CoreErrorBoundary } from "./CoreErrorBoundary";
import { CoreRouterContext } from "./CoreRouterContext";
import { CoreSidebar } from "./CoreSidebar";
import { appState } from "./state";
import { CoreApp_tenant$key } from "./__generated__/CoreApp_tenant.graphql";
import { CoreApp_viewer$key } from "./__generated__/CoreApp_viewer.graphql";
import { CoreApp__viewer$data } from "./__generated__/CoreApp__viewer.graphql";
import { CoreNav_nav$key } from "./__generated__/CoreNav_nav.graphql";
import { useBootloaderQuery$data } from "./__generated__/useBootloaderQuery.graphql";
import { CoreApp__tenant$key } from "./__generated__/CoreApp__tenant.graphql";
import { ViewerPermission } from "@workflows/business";
import { FeatureFlagProvider } from "./feature-flag/FeatureFlagProvider";
export type RouteType = ExtractNode<NonNullable<useBootloaderQuery$data["routes"]>>;
export interface CoreAppProps {
  _viewer: CoreApp__viewer$data | null;
  _viewerRef: useBootloaderQuery$data["_viewer"] | null;
  _tenant: CoreApp__tenant$key | null;
  appRoutes: RouteType[];
  children?: React.ReactNode;
  nav: CoreNav_nav$key | null;
  tenant: CoreApp_tenant$key | null;
  viewer: CoreApp_viewer$key | null;
}
export function CoreApp({
  children,
  ...props
}: CoreAppProps): JSX.Element {
  const viewer = useFragment(ViewerFragment, props.viewer);
  const _tenant = useFragment($CoreApp__tenant, props._tenant);
  const tenant = useFragment(TenantFragment, props.tenant);
  const app = useRecoilValue(appState);
  const {
    menuSize
  } = (useBreakpointMatch({
    menuSize: {
      xs: "md",
      lg: "xs"
    }
  }) as {
    menuSize: MenuSize | undefined;
  });
  React.useEffect(() => {
    const languageConfig: ConfigValueLanguage | null = tenant?.config?.find(c => c?.key === "ai.workflows.tenants.config.languages")?.value;
    void i18next.changeLanguage(languageConfig?.default);
  }, [tenant?.config]);
  const tenantCount = props._viewer?.tenantCount || 0;
  const user = {
    ...props._viewer?.user,
    ...viewer?.user
  };
  const isAnonymous = props._viewer?.user?.type === "anonymous";
  return <ViewerTenantContext tenantId={tenant?.id} userId={viewer?.user?.id} user={user} userType={props._viewer?.user?.type || undefined} viewerId={viewer?.id || undefined} tenants={getEdgeNodes(viewer?.tenants || null)} currentTenantsAndSubtenants={getEdgeNodes(props._viewer?.currentTenantsAndSubtenants || null)} roles={tenant && getEdgeNodes(tenant?.roles || null) || []} configValues={tenant?.config || []} parentId={tenant?.parentId ?? undefined}>
      <CoreRouterContext routes={props.appRoutes} baseUrl={"/"}>
        <ErrorProvider>
          <FeatureFlagProvider _viewerFragment={props._viewerRef}>
            <Permissions.Context wrn={_tenant?.wrn} permissions={(_tenant?.permissions as ViewerPermission[])}>
              <Stack gap={false} direction={menuSize === "md" ? "vertical" : "horizontal"} className={classNames("h--full", isAnonymous && "bg--muted")}>
                {viewer?.user && tenant && app.showSidebar && props.nav && !isAnonymous && <CoreSidebar nav={props.nav} tenantCount={tenantCount} />}
                <CoreErrorBoundary>{children}</CoreErrorBoundary>
              </Stack>
            </Permissions.Context>
          </FeatureFlagProvider>
        </ErrorProvider>
      </CoreRouterContext>
    </ViewerTenantContext>;
}
export const $CoreApp__viewer = (_CoreApp__viewer.hash && _CoreApp__viewer.hash !== "194149b90731a9ee03bfe123e68ae618" && console.error("The definition of 'CoreApp__viewer' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _CoreApp__viewer);
const ViewerFragment = (_CoreApp_viewer.hash && _CoreApp_viewer.hash !== "9172128f04a7303b0628b6d205d862c0" && console.error("The definition of 'CoreApp_viewer' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _CoreApp_viewer);
const $CoreApp__tenant = (_CoreApp__tenant.hash && _CoreApp__tenant.hash !== "f3acf7f79d25704c94345bc90ecd93ab" && console.error("The definition of 'CoreApp__tenant' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _CoreApp__tenant);
const TenantFragment = (_CoreApp_tenant.hash && _CoreApp_tenant.hash !== "b09cf88a5a3d770870e9ddc97250c5d5" && console.error("The definition of 'CoreApp_tenant' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _CoreApp_tenant);