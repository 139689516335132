import _AccountConfirmEmailRouteMutation from "./__generated__/AccountConfirmEmailRouteMutation.graphql";
import { graphql, newId, useHistory, useLocation, useMutation } from "@workflows/runtime-web";
import { Callout, CheckCircleIcon, LinkButton, NonIdealState, Stack, StackItem, useDocumentTitle } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { CoreSuspenseFallback } from "~/core/CoreSuspenseFallback";
import { AccountLayout } from "./AccountLayout";
import { AccountConfirmEmailRouteMutation } from "./__generated__/AccountConfirmEmailRouteMutation.graphql";
function useURLSearchParams() {
  return new URLSearchParams(useLocation().search);
}
export function AccountConfirmEmailRoute(): JSX.Element {
  const history = useHistory();
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState<"default" | "success">("default");
  const {
    t
  } = useTranslation("ai.workflows.accounts");
  const secret = useURLSearchParams().get("s");
  useDocumentTitle(t("AccountConfirmEmailRoute.pageTitle"));
  const [confirmUser, isPending] = useMutation<AccountConfirmEmailRouteMutation>(accountConfirmEmailMutation);
  React.useEffect(() => {
    if (!secret) return;
    const input = {
      clientMutationId: newId(),
      secret
    };
    confirmUser({
      variables: {
        input
      },
      onError(error) {
        console.error(error);
        setError(t("AccountConfirmEmailRoute.errors.unspecified"));
      },
      onCompleted(data) {
        if (data.confirmUser?.errors) {
          setError(t("AccountConfirmEmailRoute.errors.unspecified"));
        } else {
          setState("success");
        }
      }
    });
  }, [secret, confirmUser, history, t]);
  return <AccountLayout>
      <Stack direction="vertical" fill justify="center" align="center">
        <StackItem className="w--full" style={{
        maxWidth: "30rem"
      }}>
          <Stack direction="vertical">
            {state === "default" && <>
                {isPending && <CoreSuspenseFallback />}
                {error && <Callout intent="critical" className="mb-md">
                    {error}
                  </Callout>}
              </>}
            {state === "success" && <NonIdealState header={<CheckCircleIcon size="xl" />} description={t("AccountConfirmEmailRoute.success.description")} footer={<LinkButton intent="primary" to="/sign-in">
                    {t("AccountConfirmEmailRoute.success.goToSignIn")}
                  </LinkButton>} />}
          </Stack>
        </StackItem>
      </Stack>
    </AccountLayout>;
}
const accountConfirmEmailMutation = (_AccountConfirmEmailRouteMutation.hash && _AccountConfirmEmailRouteMutation.hash !== "342cfa88fd34935556e2da0b4feae413" && console.error("The definition of 'AccountConfirmEmailRouteMutation' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _AccountConfirmEmailRouteMutation);