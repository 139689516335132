import * as React from "react";
import { useRecoilSnapshot } from "@workflows/runtime-web";
export function CoreDebugger(): null {
  const snapshot = useRecoilSnapshot();
  React.useEffect(() => {
    for (const node of snapshot.getNodes_UNSTABLE({
      isModified: true
    })) {
      console.debug(node.key, snapshot.getLoadable(node).contents);
    }
  }, [snapshot]);
  return null;
}