import _useBootloaderQuery from "./__generated__/useBootloaderQuery.graphql";
import { ExtractNode, getEdgeNodes, graphql, useLazyLoadQuery } from "@workflows/runtime-web";
import { sort } from "@workflows/std-js/array";
import { useTenantId } from "@workflows/ui";
import * as React from "react";
import { globalConfigValues } from "./constants";
import { useBootloaderQuery, useBootloaderQuery$data } from "./__generated__/useBootloaderQuery.graphql";
export interface UseBootloaderValue {
  _viewer: useBootloaderQuery$data["_viewer"];
  _viewerRef: useBootloaderQuery$data["_viewer"];
  _tenant: useBootloaderQuery$data["_tenant"];
  appRoutes: ExtractNode<NonNullable<useBootloaderQuery$data["routes"]>>[];
  routes: ExtractNode<NonNullable<useBootloaderQuery$data["_routes"]>>[];
  tenant: useBootloaderQuery$data["tenant"];
  viewer: useBootloaderQuery$data["viewer"];
}
export function useBootloader(): UseBootloaderValue {
  const tenantId = useTenantId();
  const data = useLazyLoadQuery<useBootloaderQuery>(query, {
    tenantId,
    configValueKeys: globalConfigValues,
    tenantIds: [tenantId]
  });
  const appRoutes = React.useMemo(() => sort(getEdgeNodes(data.routes), (a, b) => a.sort.localeCompare(b.sort)), [data.routes]);
  const routes = React.useMemo(() => sort(getEdgeNodes(data._routes), (a, b) => a.sort.localeCompare(b.sort)), [data._routes]);
  return {
    _viewer: data._viewer,
    _viewerRef: data._viewer,
    viewer: data.viewer,
    _tenant: data._tenant,
    tenant: data.tenant,
    appRoutes,
    routes
  };
}

// TODO: Merge `routes` and `_routes`
const query = (_useBootloaderQuery.hash && _useBootloaderQuery.hash !== "08a8c0b198e70db3fe659ca53594dcd3" && console.error("The definition of 'useBootloaderQuery' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _useBootloaderQuery);