import _TenantList_Tenant from "./__generated__/TenantList_Tenant.graphql";
import _TenantList_Tenants from "./__generated__/TenantList_Tenants.graphql";
import _TenantListQuery from "./__generated__/TenantListQuery.graphql";
import { Button, Card, CardBody, ListView, Stack, Text } from "@workflows/ui";
import * as React from "react";
import { TenantListQuery } from "./__generated__/TenantListQuery.graphql";
import { TenantList_Tenant$data } from "./__generated__/TenantList_Tenant.graphql";
import { useSetRecoilState, graphql, useLazyLoadQuery } from "@workflows/runtime-web";
import { appSidebar } from "~/core/state";
import { useTranslation } from "react-i18next";
export function TenantList(): JSX.Element {
  const data = useLazyLoadQuery<TenantListQuery>(ViewerQuery, {
    count: 50
  });
  return <ListView<TenantList_Tenant$data> fragment={ViewerQuery_tenants} fragmentRef={data.viewer} itemFragment={ViewerQuery_tenant} component={ListItem} edgeKey="tenants" />;
}
interface ListItemProps {
  item: TenantList_Tenant$data;
}
const ListItem = ({
  item
}: ListItemProps) => {
  const {
    t
  } = useTranslation("ai.workflows.accounts");
  const showAppSidebar = useSetRecoilState(appSidebar);
  const handleClick = React.useCallback(() => {
    showAppSidebar(true);
    window.location.href = item.host.baseUrl;
  }, [item.host.baseUrl, showAppSidebar]);
  return <Card className="w--full">
      <CardBody>
        <Stack direction="horizontal" gap="sm" align="center" justify="between" fill>
          <div>
            <Text variant="headingLg">{item.name}</Text>
            <Text variant="bodyMd">{item.host.baseUrl}</Text>
          </div>
          <Button onClick={handleClick} intent="default">
            {t("TenantList.launch")}
          </Button>
        </Stack>
      </CardBody>
    </Card>;
};
const ViewerQuery = (_TenantListQuery.hash && _TenantListQuery.hash !== "d09dbbd1606e6dcbb41dc3ea4ca0a555" && console.error("The definition of 'TenantListQuery' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _TenantListQuery);
const ViewerQuery_tenants = (_TenantList_Tenants.hash && _TenantList_Tenants.hash !== "f7e9f423ec448b0bc613979fbbf07c91" && console.error("The definition of 'TenantList_Tenants' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _TenantList_Tenants);
const ViewerQuery_tenant = (_TenantList_Tenant.hash && _TenantList_Tenant.hash !== "66460c4a1e77760431686d4e5ff9224f" && console.error("The definition of 'TenantList_Tenant' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _TenantList_Tenant);