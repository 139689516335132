/* eslint-disable @typescript-eslint/no-explicit-any */
import { Environment, FetchFunction, Network, RecordSource, Store } from "@workflows/runtime-web";
import { parseCookies } from "~/core/auth";
import { config } from "./config";
const sendRequest: FetchFunction = async (params, variables, _cacheConfig) => {
  const cookies = parseCookies<{
    "wf.token": string;
  }>(document.cookie);
  const token = cookies["wf.token"];
  const requestVariables = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(token ? {
        Authorization: `Bearer ${token}`
      } : {})
    }
  };
  const body = JSON.stringify({
    query: params.text,
    variables
  });
  console.debug(`Fetching query ${params.name} with ${JSON.stringify(variables)}`);
  const response = await fetch(config.endpoints.apiGraphQL, {
    ...requestVariables,
    credentials: "include",
    body
  });
  return await response.json();
};
export const environment = new Environment({
  network: Network.create(sendRequest),
  store: new Store(new RecordSource(config.data))
});
export const environmentProvider = {
  getEnvironment() {
    return environment;
  }
};