import { BrowserRouter, Environment, RecoilRoot, RelayEnvironmentProvider } from "@workflows/runtime-web";
import { Root, TenantContext } from "@workflows/ui";
import * as React from "react";
import { config } from "~/client/config";
import { CoreErrorBoundary } from "~/core/CoreErrorBoundary";
import { CoreSuspenseFallback } from "~/core/CoreSuspenseFallback";
import { CoreTheme } from "./CoreTheme";
export interface CoreKernelProps {
  children: React.ReactNode;
  relay: Environment;
}
export function CoreKernel({
  relay,
  children
}: CoreKernelProps): JSX.Element {
  return <CoreErrorBoundary>
      <React.Suspense fallback={<CoreSuspenseFallback />}>
        <RecoilRoot>
          <RelayEnvironmentProvider environment={relay}>
            <BrowserRouter>
              <Root>
                <CoreTheme>
                  <TenantContext id={config.tenantId}>{children}</TenantContext>
                </CoreTheme>
              </Root>
            </BrowserRouter>
          </RelayEnvironmentProvider>
        </RecoilRoot>
      </React.Suspense>
    </CoreErrorBoundary>;
}