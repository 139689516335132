import _AccountAcceptInviteRouteMutation from "./__generated__/AccountAcceptInviteRouteMutation.graphql";
import { graphql, newId, useMutation } from "@workflows/runtime-web";
import { Callout, Heading, LinkButton, Stack, StackItem, useDocumentTitle, useTenantId } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useUrlSearchParams } from "~/core/useUrlSearchParams";
import { AccountLayout } from "./AccountLayout";
import { AccountSignUpForm, AccountSignUpFormProps } from "./AccountSignUpForm";
import { AccountAcceptInviteRouteMutation } from "./__generated__/AccountAcceptInviteRouteMutation.graphql";
export function AccountAcceptInviteRoute(): JSX.Element {
  const [{
    s: secret
  }] = useUrlSearchParams({
    s: ""
  });
  const tenantId = useTenantId();
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState<"default" | "success">("default");
  const {
    t
  } = useTranslation("ai.workflows.accounts");
  useDocumentTitle(t("AccountAcceptInviteRouteMutation.pageTitle"));
  const [acceptUserInvite, isPending] = useMutation<AccountAcceptInviteRouteMutation>(mutation);
  const onSubmit: AccountSignUpFormProps["onSubmit"] = React.useCallback(({
    firstName,
    lastName,
    password
  }) => {
    acceptUserInvite({
      variables: {
        input: {
          clientMutationId: newId(),
          firstName,
          lastName,
          password,
          secret,
          tenantId
        }
      },
      onError(error) {
        console.error(error);
        setError(t("AccountAcceptInviteRouteMutation.errors.unspecified"));
      },
      onCompleted(data) {
        if (data._acceptUserInvite?.errors && data._acceptUserInvite?.errors.length > 0) {
          const errors = data._acceptUserInvite.errors;
          if (errors.find(e => e && e.code === "ActionInvalid")) {
            setError(t(`AccountAcceptInviteRouteMutation.errors.invalidAction`));
          } else if (errors.find(e => e && e.code === "ActionNotFound")) {
            setError(t(`AccountAcceptInviteRouteMutation.errors.actionNotFound`));
          } else if (errors.find(e => e && e.code === "UserAlreadyActive")) {
            setError(t(`AccountAcceptInviteRouteMutation.errors.userAlreadyActive`));
          } else if (errors.find(e => e && e.code === "UserNotFound")) {
            setError(t(`AccountAcceptInviteRouteMutation.errors.userNotFound`));
          } else {
            setError(t(`AccountAcceptInviteRouteMutation.errors.unspecified`));
            console.error(errors);
          }
        } else {
          setState("success");
        }
      }
    });
  }, [acceptUserInvite, t, tenantId, secret]);
  return <AccountLayout>
      <Stack direction="vertical" fill justify="center" align="center">
        <StackItem width="1/3">
          <Stack direction="vertical">
            {state === "default" && <>
                <Heading size="sm">
                  {t("AccountAcceptInviteRouteMutation.title")}
                </Heading>

                {error && <Callout intent="critical" className="mb-md">
                    {error}
                  </Callout>}

                <AccountSignUpForm onSubmit={onSubmit} isPending={isPending} hiddenFields={["email", "companyName"]} />
              </>}

            {state === "success" && <>
                <Stack direction="vertical">
                  <StackItem>
                    {t("AccountAcceptInviteRouteMutation.successText")}
                  </StackItem>
                  <StackItem>
                    <LinkButton to="/sign-in" intent="primary" fill>
                      {t("AccountAcceptInviteRouteMutation.actions.goToSignIn")}
                    </LinkButton>
                  </StackItem>
                </Stack>
              </>}
          </Stack>
        </StackItem>
      </Stack>
    </AccountLayout>;
}
const mutation = (_AccountAcceptInviteRouteMutation.hash && _AccountAcceptInviteRouteMutation.hash !== "12e5235409ea1397b4908b2206929471" && console.error("The definition of 'AccountAcceptInviteRouteMutation' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _AccountAcceptInviteRouteMutation);