/**
 * @generated SignedSource<<1e83c12cd7b2d73877fd35f75f4029f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type _SetUserPasswordInput = {
  clientMutationId: string;
  password: string;
  secret: string;
};
export type AccountSetPasswordRouteMutation$variables = {
  input: _SetUserPasswordInput;
};
export type AccountSetPasswordRouteMutation$data = {
  readonly _setUserPassword: {
    readonly clientMutationId: string;
    readonly errors: ReadonlyArray<{
      readonly code: string;
      readonly message: string;
      readonly path: ReadonlyArray<string>;
      readonly type: string;
    }> | null;
  } | null;
};
export type AccountSetPasswordRouteMutation = {
  response: AccountSetPasswordRouteMutation$data;
  variables: AccountSetPasswordRouteMutation$variables;
};
const node: ConcreteRequest = function () {
  var v0 = [{
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "input"
    }],
    v1 = [{
      "alias": null,
      "args": [{
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }],
      "concreteType": "_SetUserPasswordPayload",
      "kind": "LinkedField",
      "name": "_setUserPassword",
      "plural": false,
      "selections": [{
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }, {
        "alias": null,
        "args": null,
        "concreteType": "MutationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [{
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }],
        "storageKey": null
      }],
      "storageKey": null
    }];
  return {
    "fragment": {
      "argumentDefinitions": v0 /*: any*/,
      "kind": "Fragment",
      "metadata": null,
      "name": "AccountSetPasswordRouteMutation",
      "selections": v1 /*: any*/,
      "type": "Mutation",
      "abstractKey": null
    },
    "kind": "Request",
    "operation": {
      "argumentDefinitions": v0 /*: any*/,
      "kind": "Operation",
      "name": "AccountSetPasswordRouteMutation",
      "selections": v1 /*: any*/
    },

    "params": {
      "cacheID": "9d54185560a79c1626cb43feb9a8f8d5",
      "id": null,
      "metadata": {},
      "name": "AccountSetPasswordRouteMutation",
      "operationKind": "mutation",
      "text": "mutation AccountSetPasswordRouteMutation(\n  $input: _SetUserPasswordInput!\n) {\n  _setUserPassword(input: $input) {\n    clientMutationId\n    errors {\n      code\n      path\n      message\n      type\n    }\n  }\n}\n"
    }
  };
}();
(node as any).hash = "911e5a9d3574dbf401a195a513538c5b";
export default node;