import _FeatureFlagProvider_features from "./__generated__/FeatureFlagProvider_features.graphql";
import * as React from "react";
import { FeatureFlagContext } from "./FeatureFlagContext";
import { graphql, useFragment } from "@workflows/runtime-web";
interface FeatureFlagProviderProps {
  _viewerFragment: any;
  children: React.ReactNode;
}
export function FeatureFlagProvider({
  _viewerFragment,
  children
}: FeatureFlagProviderProps) {
  const features = useFragment(FeatureFlagFragment, _viewerFragment);
  return <FeatureFlagContext.Provider value={{
    features
  }}>
      {children}
    </FeatureFlagContext.Provider>;
}
const FeatureFlagFragment = (_FeatureFlagProvider_features.hash && _FeatureFlagProvider_features.hash !== "374bbb65aefd0a432bf78f6cfddee548" && console.error("The definition of 'FeatureFlagProvider_features' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _FeatureFlagProvider_features);