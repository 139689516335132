import { Button, ChoiceList, ChoiceListProps, Form, Form2, TextField, TextFieldProps, useForm } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { ConfigValueSignup, useConfigValue } from "~/core/configValues";
import { getTranslationComponent } from "./AccountSignUpDisclaimer";
import { useLegalAgreements } from "./hooks/useLegalAgreements";
export interface AccountSignUpFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  companyName: string;
  tosPrivacy: boolean;
}
export interface AccountSignUpFormProps {
  isPending: boolean;
  onSubmit: (values: AccountSignUpFormValues) => void;
  hiddenFields?: (keyof AccountSignUpFormValues)[];
}
export function AccountSignUpForm({
  onSubmit,
  isPending,
  hiddenFields = []
}: AccountSignUpFormProps): JSX.Element {
  const form = useForm("AccountSignUpForm");
  const {
    t
  } = useTranslation("ai.workflows.accounts", {
    keyPrefix: "AccountSignUpForm"
  });
  const legalAgreements = useLegalAgreements();
  const handleSubmit = async () => {
    const {
      isValid
    } = await form.validate();
    if (!isValid) return;
    onSubmit(form.getValues());
  };
  const validatePassword = (password: string) => {
    password = String(password).trim();
    if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/g)) {
      return t("fields.password.required");
    }
    return true;
  };
  const signupConfig = useConfigValue<ConfigValueSignup>("ai.workflows.tenants.config.signup");
  return <Form2 name="AccountSignUpForm" onSubmit={handleSubmit}>
      <Form.ControlInput<TextFieldProps> name="firstName" autoFocus label={t("fields.firstName.label")} component={TextField} required={t("fields.firstName.required")} />

      <Form.ControlInput<TextFieldProps> name="lastName" autoFocus label={t("fields.lastName.label")} component={TextField} required={t("fields.lastName.required")} />

      {signupConfig?.hasFieldCompany && !hiddenFields.includes("companyName") && <Form.ControlInput<TextFieldProps> name="companyName" autoFocus label={t("fields.company.label")} component={TextField} required={t("fields.company.required")} />}

      {!hiddenFields.includes("email") && <Form.ControlInput<TextFieldProps> name="email" autoFocus label={t("fields.email.label")} component={TextField} required={t("fields.email.required")} />}

      <Form.ControlInput<TextFieldProps> name="password" type="password" autoFocus label={t("fields.password.label")} component={TextField} validate={validatePassword} />

      {legalAgreements && <Form.ControlInput<ChoiceListProps> name="tosPrivacy" component={ChoiceList} required={t("fields.tosPrivacy.required")} type="checkbox" validationMode="onChange" options={[{
      label: getTranslationComponent(legalAgreements),
      value: "true"
    }]} />}

      <Button intent="primary" onClick={handleSubmit} isLoading={isPending} fill>
        {t("actions.submit")}
      </Button>
    </Form2>;
}