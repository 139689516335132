/**
 * @generated SignedSource<<5b05b9df0daabcbc9b74036a2499c53b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeatureFlagProvider_features$data = {
  readonly features: ReadonlyArray<{
    readonly id: string | null;
    readonly identifier: string | null;
  }> | null;
  readonly " $fragmentType": "FeatureFlagProvider_features";
};
export type FeatureFlagProvider_features$key = {
  readonly " $data"?: FeatureFlagProvider_features$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeatureFlagProvider_features">;
};
const node: ReaderFragment = {
  "argumentDefinitions": [{
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantId"
  }],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeatureFlagProvider_features",
  "selections": [{
    "alias": null,
    "args": [{
      "kind": "Variable",
      "name": "tenantId",
      "variableName": "tenantId"
    }],
    "concreteType": "Feature",
    "kind": "LinkedField",
    "name": "features",
    "plural": true,
    "selections": [{
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }, {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }],
    "storageKey": null
  }],
  "type": "_Viewer",
  "abstractKey": null
};
(node as any).hash = "374bbb65aefd0a432bf78f6cfddee548";
export default node;