/**
 * @generated SignedSource<<c0ebb1228bf8fd7f03ed7b70b2ce717f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountTenantSwitcherQuery$variables = {};
export type AccountTenantSwitcherQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"AccountTenantSwitcher_tenants">;
  } | null;
};
export type AccountTenantSwitcherQuery = {
  response: AccountTenantSwitcherQuery$data;
  variables: AccountTenantSwitcherQuery$variables;
};
const node: ConcreteRequest = function () {
  var v0 = [{
      "kind": "Literal",
      "name": "first",
      "value": 50
    }],
    v1 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    };
  return {
    "fragment": {
      "argumentDefinitions": [],
      "kind": "Fragment",
      "metadata": null,
      "name": "AccountTenantSwitcherQuery",
      "selections": [{
        "alias": "viewer",
        "args": null,
        "concreteType": "_Viewer",
        "kind": "LinkedField",
        "name": "_viewer",
        "plural": false,
        "selections": [{
          "args": [{
            "kind": "Literal",
            "name": "count",
            "value": 50
          }],
          "kind": "FragmentSpread",
          "name": "AccountTenantSwitcher_tenants"
        }],
        "storageKey": null
      }],
      "type": "Query",
      "abstractKey": null
    },
    "kind": "Request",
    "operation": {
      "argumentDefinitions": [],
      "kind": "Operation",
      "name": "AccountTenantSwitcherQuery",
      "selections": [{
        "alias": "viewer",
        "args": null,
        "concreteType": "_Viewer",
        "kind": "LinkedField",
        "name": "_viewer",
        "plural": false,
        "selections": [{
          "alias": null,
          "args": v0 /*: any*/,
          "concreteType": "TenantConnectionType",
          "kind": "LinkedField",
          "name": "tenants",
          "plural": false,
          "selections": [{
            "alias": null,
            "args": null,
            "concreteType": "TenantConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [{
              "alias": null,
              "args": null,
              "concreteType": "_Tenant",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [v1 /*: any*/, {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }, {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "baseUrl",
                "storageKey": null
              }, {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              }],
              "storageKey": null
            }, {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }],
            "storageKey": null
          }, {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [{
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }, {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }],
            "storageKey": null
          }],
          "storageKey": "tenants(first:50)"
        }, {
          "alias": null,
          "args": v0 /*: any*/,
          "filters": null,
          "handle": "connection",
          "key": "AccountTenantSwitcher_tenants",
          "kind": "LinkedHandle",
          "name": "tenants"
        }, v1 /*: any*/],

        "storageKey": null
      }]
    },
    "params": {
      "cacheID": "0a3d8efb969f00a234fefb39f5469127",
      "id": null,
      "metadata": {},
      "name": "AccountTenantSwitcherQuery",
      "operationKind": "query",
      "text": "query AccountTenantSwitcherQuery {\n  viewer: _viewer {\n    ...AccountTenantSwitcher_tenants_knr29\n    id\n  }\n}\n\nfragment AccountTenantSwitcher_tenants_knr29 on _Viewer {\n  tenants(first: 50) {\n    edges {\n      node {\n        id\n        name\n        baseUrl\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
    }
  };
}();
(node as any).hash = "c5f4bb23836bf45dcbe9b818a0a07cf2";
export default node;