/**
 * @generated SignedSource<<68398e2a6366ed9b2de58b44fe1999fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoreApp_tenant$data = {
  readonly config: ReadonlyArray<{
    readonly key: string;
    readonly value: any;
  } | null> | null;
  readonly id: string;
  readonly name: string;
  readonly parentId: string | null;
  readonly roles: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "CoreApp_tenant";
};
export type CoreApp_tenant$key = {
  readonly " $data"?: CoreApp_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoreApp_tenant">;
};
const node: ReaderFragment = function () {
  var v0 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    v1 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    };
  return {
    "argumentDefinitions": [{
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "configValueKeys"
    }],
    "kind": "Fragment",
    "metadata": null,
    "name": "CoreApp_tenant",
    "selections": [v0 /*: any*/, v1 /*: any*/, {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentId",
      "storageKey": null
    }, {
      "alias": null,
      "args": null,
      "concreteType": "RoleConnection",
      "kind": "LinkedField",
      "name": "roles",
      "plural": false,
      "selections": [{
        "alias": null,
        "args": null,
        "concreteType": "RoleEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [{
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [v0 /*: any*/, v1 /*: any*/],

          "storageKey": null
        }],
        "storageKey": null
      }],
      "storageKey": null
    }, {
      "alias": null,
      "args": [{
        "kind": "Variable",
        "name": "keys",
        "variableName": "configValueKeys"
      }],
      "concreteType": "ConfigValue",
      "kind": "LinkedField",
      "name": "config",
      "plural": true,
      "selections": [{
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      }, {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }],
      "storageKey": null
    }],
    "type": "Tenant",
    "abstractKey": null
  };
}();
(node as any).hash = "b09cf88a5a3d770870e9ddc97250c5d5";
export default node;