/**
 * @generated SignedSource<<53838707f65e3a2ab962fcf14c505b62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoreApp_viewer$data = {
  readonly id: string | null;
  readonly tenants: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null> | null;
  } | null;
  readonly user: {
    readonly displayName: string;
    readonly email: string;
    readonly firstName: string | null;
    readonly id: string;
    readonly lastName: string | null;
    readonly name: string;
  } | null;
  readonly " $fragmentType": "CoreApp_viewer";
};
export type CoreApp_viewer$key = {
  readonly " $data"?: CoreApp_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoreApp_viewer">;
};
const node: ReaderFragment = function () {
  var v0 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    v1 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    };
  return {
    "argumentDefinitions": [{
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tenantId"
    }],
    "kind": "Fragment",
    "metadata": null,
    "name": "CoreApp_viewer",
    "selections": [v0 /*: any*/, {
      "alias": null,
      "args": [{
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
      }],
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [v0 /*: any*/, v1 /*: any*/, {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      }, {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      }, {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      }, {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      }],
      "storageKey": null
    }, {
      "alias": null,
      "args": null,
      "concreteType": "TenantConnection",
      "kind": "LinkedField",
      "name": "tenants",
      "plural": false,
      "selections": [{
        "alias": null,
        "args": null,
        "concreteType": "TenantEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [{
          "alias": null,
          "args": null,
          "concreteType": "Tenant",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [v0 /*: any*/, v1 /*: any*/],

          "storageKey": null
        }],
        "storageKey": null
      }],
      "storageKey": null
    }],
    "type": "Viewer",
    "abstractKey": null
  };
}();
(node as any).hash = "9172128f04a7303b0628b6d205d862c0";
export default node;