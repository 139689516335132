/**
 * @generated SignedSource<<eddbd7014fcf8f6ac4b53e863c37b577>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type _AcceptUserInviteInput = {
  clientMutationId: string;
  contactEmail?: string | null;
  firstName: string;
  lastName: string;
  password: string;
  secret: string;
  tenantId: string;
  title?: string | null;
};
export type AccountAcceptInviteRouteMutation$variables = {
  input: _AcceptUserInviteInput;
};
export type AccountAcceptInviteRouteMutation$data = {
  readonly _acceptUserInvite: {
    readonly errors: ReadonlyArray<{
      readonly code: string;
      readonly message: string;
      readonly path: ReadonlyArray<string>;
    }> | null;
  } | null;
};
export type AccountAcceptInviteRouteMutation = {
  response: AccountAcceptInviteRouteMutation$data;
  variables: AccountAcceptInviteRouteMutation$variables;
};
const node: ConcreteRequest = function () {
  var v0 = [{
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "input"
    }],
    v1 = [{
      "alias": null,
      "args": [{
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }],
      "concreteType": "_AcceptUserInvitePayload",
      "kind": "LinkedField",
      "name": "_acceptUserInvite",
      "plural": false,
      "selections": [{
        "alias": null,
        "args": null,
        "concreteType": "MutationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [{
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }],
        "storageKey": null
      }],
      "storageKey": null
    }];
  return {
    "fragment": {
      "argumentDefinitions": v0 /*: any*/,
      "kind": "Fragment",
      "metadata": null,
      "name": "AccountAcceptInviteRouteMutation",
      "selections": v1 /*: any*/,
      "type": "Mutation",
      "abstractKey": null
    },
    "kind": "Request",
    "operation": {
      "argumentDefinitions": v0 /*: any*/,
      "kind": "Operation",
      "name": "AccountAcceptInviteRouteMutation",
      "selections": v1 /*: any*/
    },

    "params": {
      "cacheID": "e41a6ee3391ad5653882d16103d3ac78",
      "id": null,
      "metadata": {},
      "name": "AccountAcceptInviteRouteMutation",
      "operationKind": "mutation",
      "text": "mutation AccountAcceptInviteRouteMutation(\n  $input: _AcceptUserInviteInput!\n) {\n  _acceptUserInvite(input: $input) {\n    errors {\n      code\n      path\n      message\n    }\n  }\n}\n"
    }
  };
}();
(node as any).hash = "12e5235409ea1397b4908b2206929471";
export default node;