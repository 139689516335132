/* eslint-disable node/no-unsupported-features/es-syntax */

// The mapping contains the different entry points
// https://www.serverless.com/framework/docs/providers/aws/guide/functions#lambda-function-urls
export const apps: Record<string, any> = {
  // Portal Landing Pages
  "workflows/DcocomPrivatePortalLandingRoute:DcocomPrivatePortalLandingRoute": () => import("./dcocom/portalLanding/DcocomPrivatePortalLandingRoute"),
  "workflows/DcocomPublicPortalLandingRoute:DcocomPublicPortalLandingRoute": () => import("./dcocom/portalLanding/DcocomPublicPortalLandingRoute"),
  // Complaint Intake
  "workflows/DcocomAgencySelectionRoute:DcocomAgencySelectionRoute": () => import("./dcocom/workflows/complaint/DcocomAgencySelectionRoute"),
  "workflows/DcocomComplaintIntakeRoute:DcocomComplaintIntakeRoute": () => import("./dcocom/workflows/complaint/DcocomComplaintIntakeRoute"),
  // Supplychain Intake
  "workflows/DcocomSupplychainRoute:DcocomSupplychainRoute": () => import("./dcocom/workflows/supplychain/DcocomSupplychainRoute"),
  // Whistleblow Intake
  "workflows/DcocomWhistleblowRoute:DcocomWhistleblowRoute": () => import("./dcocom/workflows/whistleblow/DcocomWhistleblowRoute"),
  // Dashboard
  "workflows/DashboardRoute:DashboardRoute": () => import("./dashboard/DashboardRoute"),
  "smartconex:DashboardRoute": () => import("./de.smartconex.vertragsgenerator/dashboard/DashboardRoute"),
  // Matters
  "workflows/MattersRoute:MattersRoute": () => import("./matters/Matters/MattersRoute"),
  //TODO: Remove me when every tenant fixtures is fixed

  MattersRoute: () => import("./matters/Matters/MattersRoute"),
  "workflows/MattersRoute:MattersInboxRoute": () => import("./matters/Matters/MattersInboxRoute"),
  // "workflows/MatterRoute:MatterRoute": () =>
  //   import("./matters/Matter/MatterRoute"),

  // Document Matters
  "workflows/DocumentMattersRoute:DocumentMattersRoute": () => import("./matters/Matters/Documents/DocumentMattersRoute"),
  "workflows/DocumentMattersInboxRoute:DocumentMattersInboxRoute": () => import("./matters/Matters/Documents/DocumentMattersInboxRoute"),
  // Tasks
  // "workflows/TaskRoute:TaskRoute": () => import("./tasks/TaskPage"),

  "workflows/TasksRoute:TasksRoute": () => import("./tasks/TasksRoute"),
  TasksRoute: () => import("./tasks/TasksRoute"),
  // TenantSettings
  TenantSettingsRoute: () => import("./tenantSettings/TenantSettingsRoute"),
  // Flows
  FlowsRoute: () => import("./flows/FlowsRoute"),
  "workflows/FlowSuccess:FlowSuccess": () => import("./flows/FlowSuccess"),
  // Admin
  "workflows/AdminRoute:AdminRoute": () => import("./admin/AdminRoute"),
  // Portal
  "workflows/PortalRoute:PortalRoute": () => import("./portal/PortalRoute"),
  // Tenants
  "workflows/AgadTenantsRoute:TenantsRoute": () => import("./de.agad.dpm/tenants/TenantsRoute"),
  "workflows/AgadTenantsRoute:ProcessingActivitiesDashboardRoute": () => import("./de.agad.dpm/dashboard/ProcessingActivitiesDashboardRoute"),
  // SignIn
  SignInRoute: () => import("./accounts/signIn/SignInRoute"),
  // Smartconex
  "smartconex:DocumentsRoute": () => import("./de.smartconex.vertragsgenerator/documents/DocumentsRoute"),
  // Glossary
  "smartconex:GlossaryRoute": () => import("./de.smartconex.vertragsgenerator/glossary/GlossaryRoute"),
  // Templates
  "smartconex:TemplatesRoute": () => import("./de.smartconex.vertragsgenerator/templates/TemplatesRoute")
};