/**
 * @generated SignedSource<<9cdb89bbe5ab1354c86cb91893b945fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type _UserType = "anonymous" | "bot" | "user" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CoreApp__viewer$data = {
  readonly currentTenantsAndSubtenants: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      };
    } | null>;
  } | null;
  readonly tenantCount: number | null;
  readonly user: {
    readonly code: string | null;
    readonly type: _UserType | null;
  } | null;
  readonly " $fragmentType": "CoreApp__viewer";
};
export type CoreApp__viewer$key = {
  readonly " $data"?: CoreApp__viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoreApp__viewer">;
};
const node: ReaderFragment = {
  "argumentDefinitions": [{
    "kind": "RootArgument",
    "name": "tenantId"
  }, {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantIds"
  }],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoreApp__viewer",
  "selections": [{
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "tenantCount",
    "storageKey": null
  }, {
    "alias": null,
    "args": [{
      "kind": "Variable",
      "name": "tenantId",
      "variableName": "tenantId"
    }],
    "concreteType": "_User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [{
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }, {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }],
    "storageKey": null
  }, {
    "alias": "currentTenantsAndSubtenants",
    "args": [{
      "kind": "Variable",
      "name": "tenantIds",
      "variableName": "tenantIds"
    }],
    "concreteType": "TenantConnectionType",
    "kind": "LinkedField",
    "name": "tenants",
    "plural": false,
    "selections": [{
      "alias": null,
      "args": null,
      "concreteType": "TenantConnectionEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [{
        "alias": null,
        "args": null,
        "concreteType": "_Tenant",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [{
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }],
        "storageKey": null
      }],
      "storageKey": null
    }],
    "storageKey": null
  }],
  "type": "_Viewer",
  "abstractKey": null
};
(node as any).hash = "194149b90731a9ee03bfe123e68ae618";
export default node;