/**
 * @generated SignedSource<<3668cd14b62d1d5a4b7c7bbd879ddb49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExecuteActionInput = {
  clientMutationId: string;
  secret: string;
  tenantId: string;
};
export type ActionMagicLinkMutation$variables = {
  input: ExecuteActionInput;
};
export type ActionMagicLinkMutation$data = {
  readonly executeAction: {
    readonly action: {
      readonly id: string;
      readonly payload: {
        readonly token?: string;
      };
      readonly type: {
        readonly identifier: string;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: string | null;
    } | null> | null;
  } | null;
};
export type ActionMagicLinkMutation = {
  response: ActionMagicLinkMutation$data;
  variables: ActionMagicLinkMutation$variables;
};
const node: ConcreteRequest = function () {
  var v0 = [{
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "input"
    }],
    v1 = [{
      "kind": "Variable",
      "name": "input",
      "variableName": "input"
    }],
    v2 = {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    v3 = {
      "alias": null,
      "args": null,
      "concreteType": "ObjectType",
      "kind": "LinkedField",
      "name": "type",
      "plural": false,
      "selections": [{
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "identifier",
        "storageKey": null
      }],
      "storageKey": null
    },
    v4 = {
      "kind": "InlineFragment",
      "selections": [{
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }],
      "type": "AiWorkflowsUsersActionsMagicLinkPayload",
      "abstractKey": null
    },
    v5 = {
      "alias": null,
      "args": null,
      "concreteType": "InputError",
      "kind": "LinkedField",
      "name": "errors",
      "plural": true,
      "selections": [{
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      }],
      "storageKey": null
    };
  return {
    "fragment": {
      "argumentDefinitions": v0 /*: any*/,
      "kind": "Fragment",
      "metadata": null,
      "name": "ActionMagicLinkMutation",
      "selections": [{
        "alias": null,
        "args": v1 /*: any*/,
        "concreteType": "ExecuteActionPayload",
        "kind": "LinkedField",
        "name": "executeAction",
        "plural": false,
        "selections": [{
          "alias": null,
          "args": null,
          "concreteType": "Action",
          "kind": "LinkedField",
          "name": "action",
          "plural": false,
          "selections": [v2 /*: any*/, v3 /*: any*/, {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "payload",
            "plural": false,
            "selections": [v4 /*: any*/],

            "storageKey": null
          }],
          "storageKey": null
        }, v5 /*: any*/],

        "storageKey": null
      }],
      "type": "Mutation",
      "abstractKey": null
    },
    "kind": "Request",
    "operation": {
      "argumentDefinitions": v0 /*: any*/,
      "kind": "Operation",
      "name": "ActionMagicLinkMutation",
      "selections": [{
        "alias": null,
        "args": v1 /*: any*/,
        "concreteType": "ExecuteActionPayload",
        "kind": "LinkedField",
        "name": "executeAction",
        "plural": false,
        "selections": [{
          "alias": null,
          "args": null,
          "concreteType": "Action",
          "kind": "LinkedField",
          "name": "action",
          "plural": false,
          "selections": [v2 /*: any*/, v3 /*: any*/, {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "payload",
            "plural": false,
            "selections": [{
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }, v4 /*: any*/],

            "storageKey": null
          }],
          "storageKey": null
        }, v5 /*: any*/],

        "storageKey": null
      }]
    },
    "params": {
      "cacheID": "7ef39bd4d0ac5db97c2a59d12bbd50ac",
      "id": null,
      "metadata": {},
      "name": "ActionMagicLinkMutation",
      "operationKind": "mutation",
      "text": "mutation ActionMagicLinkMutation(\n  $input: ExecuteActionInput!\n) {\n  executeAction(input: $input) {\n    action {\n      id\n      type {\n        identifier\n      }\n      payload {\n        __typename\n        ... on AiWorkflowsUsersActionsMagicLinkPayload {\n          token\n        }\n      }\n    }\n    errors {\n      code\n    }\n  }\n}\n"
    }
  };
}();
(node as any).hash = "60bfadad8a8f2a565072994e0c10bd28";
export default node;