import { TenantPermission } from "@workflows/business";
import { BackgroundContext, BrandLogo, Button, CogIcon, ColumnBody, ColumnFooter, Drawer, Link, LogoutIcon, MenuIcon, MenuSize, Nav, OfficeBuildingIcon, Permissions, Stack, StackItem, SupportIcon, Toaster, useBreakpointMatch, useDialog } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { AccountTenantSwitcherDialog } from "~/accounts/AccountTenantSwitcherDialog";
import { signOut } from "~/core/auth";
import { CoreErrorBoundary } from "./CoreErrorBoundary";
import { CoreNav } from "./CoreNav";
import { CoreNav_nav$key } from "./__generated__/CoreNav_nav.graphql";
export interface CoreSidebarProps {
  nav: CoreNav_nav$key;
  tenantCount: number;
}
export function CoreSidebar({
  nav,
  tenantCount
}: CoreSidebarProps): JSX.Element {
  const tenantSwitcher = useDialog();
  const {
    t
  } = useTranslation("ai.workflows.core");
  const [isOpen, setIsOpen] = React.useState(false);
  const handleSignOut = (event: React.SyntheticEvent) => {
    event.preventDefault();
    handleClick();
    signOut();
  };
  const {
    menuSize
  } = (useBreakpointMatch({
    menuSize: {
      xs: "md",
      lg: "xs"
    }
  }) as {
    menuSize: MenuSize | undefined;
  });
  const handleClick = React.useCallback(() => {
    menuSize === "md" && setIsOpen(!isOpen);
  }, [isOpen, menuSize]);
  const handleOpenTenantSwitcher = (e: React.SyntheticEvent) => {
    e.preventDefault();
    tenantSwitcher.openDialog();
  };
  const navColumn = <BackgroundContext background={"muted"}>
      <ColumnBody inset>
        <CoreErrorBoundary>
          <CoreNav nav={nav} size={menuSize} onClick={handleClick} />
        </CoreErrorBoundary>
      </ColumnBody>
      <ColumnFooter borderless {...menuSize === "md" && {
      align: "start"
    }}>
        <Nav size={menuSize}>
          {tenantCount >= 2 && <Nav.Item key="tenants" to="/account/tenants" label="Tenants" onClick={handleOpenTenantSwitcher} icon={<OfficeBuildingIcon />} />}
          <Permissions.Gate permission={TenantPermission.ViewSettings}>
            <Nav.Item key="tenantSettings" to="/tenantSettings" label={t("CoreSidebar.tenantSettings")} icon={<CogIcon />} exact />
          </Permissions.Gate>

          <Nav.Item key="support" href={"mailto:support@smartvokat.com"} label={t("CoreSidebar.support")} icon={<SupportIcon />} />
          <Nav.Item key="logout" to="/sign-out" label={t("CoreSidebar.logout")} icon={<LogoutIcon />} onClick={handleSignOut} exact />
        </Nav>
      </ColumnFooter>
    </BackgroundContext>;
  const brandLink = <Link to="/">
      <BrandLogo size="xs" framed />
    </Link>;
  return <Stack direction="vertical" align="start" className="border--r border--muted bg--muted">
      <Stack direction="vertical" grow={1}>
        <StackItem className="pt--md px--md">
          {menuSize === "md" ? <Stack direction="horizontal" justify="center">
              {brandLink}
              <Button icon={<MenuIcon />} onClick={handleClick} />
            </Stack> : <Stack direction="horizontal" justify="center">
              {brandLink}
            </Stack>}
        </StackItem>
        {menuSize === "md" ? <Drawer isOpen={isOpen} position="start" onClose={handleClick} width="auto">
            {navColumn}
          </Drawer> : navColumn}
      </Stack>
      <AccountTenantSwitcherDialog isOpen={tenantCount >= 2 && tenantSwitcher.isDialogOpen} onClose={tenantSwitcher.closeDialog} />
      <Toaster />
    </Stack>;
}