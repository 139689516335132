/**
 * @generated SignedSource<<a41991d31e9f22c7bba9be5706727ca1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountTenantSwitcher_tenants$data = {
  readonly tenants: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly baseUrl: string;
        readonly id: string;
        readonly name: string;
      };
    } | null>;
  } | null;
  readonly " $fragmentType": "AccountTenantSwitcher_tenants";
};
export type AccountTenantSwitcher_tenants$key = {
  readonly " $data"?: AccountTenantSwitcher_tenants$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountTenantSwitcher_tenants">;
};
const node: ReaderFragment = {
  "argumentDefinitions": [{
    "defaultValue": 100,
    "kind": "LocalArgument",
    "name": "count"
  }, {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  }],
  "kind": "Fragment",
  "metadata": {
    "connection": [{
      "count": "count",
      "cursor": "cursor",
      "direction": "forward",
      "path": ["tenants"]
    }]
  },
  "name": "AccountTenantSwitcher_tenants",
  "selections": [{
    "alias": "tenants",
    "args": null,
    "concreteType": "TenantConnectionType",
    "kind": "LinkedField",
    "name": "__AccountTenantSwitcher_tenants_connection",
    "plural": false,
    "selections": [{
      "alias": null,
      "args": null,
      "concreteType": "TenantConnectionEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [{
        "alias": null,
        "args": null,
        "concreteType": "_Tenant",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [{
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "baseUrl",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }],
        "storageKey": null
      }, {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }],
      "storageKey": null
    }, {
      "alias": null,
      "args": null,
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [{
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      }, {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }],
      "storageKey": null
    }],
    "storageKey": null
  }],
  "type": "_Viewer",
  "abstractKey": null
};
(node as any).hash = "f91bab42e76550badd3495c1d5859dc1";
export default node;