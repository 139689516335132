import { Route, Switch, useFragment } from "@workflows/runtime-web";
import { intersects } from "@workflows/std-js/array";
import * as React from "react";
import { AccountAcceptInviteRoute } from "~/accounts/AccountAcceptInviteRoute";
import { AccountConfirmEmailRoute } from "~/accounts/AccountConfirmEmailRoute";
import { AccountRecoverPasswordRoute } from "~/accounts/AccountRecoverPasswordRoute";
import { AccountSetNewPasswordRoute } from "~/accounts/AccountSetNewPasswordRoute";
import { AccountSignUpRoute } from "~/accounts/AccountSignUpRoute";
import { ActionRoute } from "~/actions/ActionRoute";
import { AppRenderRoute } from "~/apps/AppRenderRoute";
import { AccountSetPasswordRoute } from "../accounts/AccountSetPasswordRoute";
import { ChooseTenantRoute } from "../accounts/chooseTenant/ChooseTenantRoute";
import { SignInRoute } from "../accounts/signIn/SignInRoute";
import { $CoreApp__viewer, CoreApp } from "./CoreApp";
import { CoreError404 } from "./CoreError404";
import { useBootloader } from "./useBootloaderQuery";
import { AnonymousSignInRoute } from "~/accounts/anonymousSignIn/AnonymousSignInRoute";
import { CoreApp__viewer$key } from "./__generated__/CoreApp__viewer.graphql";
import { AnonymousMatterRoute } from "~/matters/AnonymousMatter/AnonymousMatterRoute";
import { CoreDebugRoute } from "~/core/CoreDebugRoute";
import { CoreResetRoute } from "~/core/CoreResetRoute";
import { PublicRoute } from "./PublicRoute";
import { ProtectedRoute } from "./ProtectedRoute";
import { CoreDebugger } from "~/core/CoreDebugger";
export function CoreRouter(): JSX.Element {
  const {
    _viewer,
    _viewerRef,
    _tenant,
    appRoutes,
    routes,
    tenant,
    viewer
  } = useBootloader();
  const isAuthenticated = viewer != null;
  const dynamicRoutes = React.useMemo(() => {
    const context = [`authenticated:${isAuthenticated}`, `authenticated:*`];
    return routes.filter(r => intersects((r.context as any), context));
  }, [isAuthenticated, routes]);
  const _viewerFragment = useFragment<CoreApp__viewer$key>($CoreApp__viewer, _viewer);
  const isAnonymousUser = _viewerFragment?.user?.type === "anonymous";
  return <CoreApp _viewer={_viewerFragment} _viewerRef={_viewerRef} _tenant={_tenant} appRoutes={appRoutes} nav={tenant} tenant={tenant} viewer={viewer}>
      <Switch>
        <Route path="/go/:actionId" component={ActionRoute} />
        <Route path="/sign-in-code" component={AnonymousSignInRoute} />

        <PublicRoute path="/recover-password">
          <AccountRecoverPasswordRoute />
        </PublicRoute>
        <PublicRoute path="/reset-password">
          <AccountSetNewPasswordRoute />
        </PublicRoute>
        <PublicRoute path="/set-password">
          <AccountSetPasswordRoute />
        </PublicRoute>
        <PublicRoute path="/confirm-email">
          <AccountConfirmEmailRoute />
        </PublicRoute>
        <PublicRoute path="/accept-invite">
          <AccountAcceptInviteRoute />
        </PublicRoute>
        <PublicRoute path="/sign-up">
          <AccountSignUpRoute />
        </PublicRoute>
        <PublicRoute path="/sign-in">
          <SignInRoute />
        </PublicRoute>

        <ProtectedRoute path="/choose-tenant">
          <ChooseTenantRoute />
        </ProtectedRoute>
        <ProtectedRoute path="/-/debug">
          <CoreDebugRoute />
        </ProtectedRoute>
        <ProtectedRoute path="/-/reset">
          <CoreResetRoute />
        </ProtectedRoute>

        {isAnonymousUser && <Route path="/" exact component={AnonymousMatterRoute} />}
        {dynamicRoutes.map(route => <Route key={route.id} path={route.path || undefined} exact={route.exact || undefined} render={props => <AppRenderRoute {...props} handler={route.handler} />} />)}
        <Route component={CoreError404} />
      </Switch>
      {process.env.NODE_ENV === "development" && isAuthenticated ? <CoreDebugger /> : null}
    </CoreApp>;
}