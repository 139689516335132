import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, FormValues, Link, Stack, StackItem, TextField, TextFieldProps, useForm } from "@workflows/ui";
export interface Props {
  onSubmit: ({
    email,
    code
  }: FormValues) => void;
  loading: boolean;
}
export const AnonymousSignInForm: React.FC<Props> = ({
  onSubmit,
  loading
}) => {
  const form = useForm("SignInForm");
  const {
    t
  } = useTranslation("ai.workflows.accounts");
  const handleSubmit = async () => {
    const {
      isValid
    } = await form.validate();
    if (!isValid) {
      return;
    }
    const values = form.getValues();
    onSubmit?.(values);
  };
  return <Form name={"SignInForm"} onSubmit={handleSubmit} defaultValues={{
    email: "",
    code: ""
  }}>
      <Stack direction="vertical" gap="lg">
        <Form.ControlInput<TextFieldProps> htmlFor="code" label={t("form.fields.code.label")} id="code" component={TextField} type="code" name="code" placeholder={t("form.fields.code.placeholder")} required={t("form.fields.code.required")} />
        <Button intent="primary" onClick={handleSubmit} fill isLoading={loading}>
          {t("signIn.label")}
        </Button>
        <StackItem align="center">
          <Link to="/sign-in">{t("SignInForm.link.signIn")}</Link>
        </StackItem>
      </Stack>
    </Form>;
};