import * as React from "react";
import { useHistory } from "@workflows/runtime-web";
interface ActionRedirectProps {
  secret: string;
  to: string;
}
export function ActionRedirect({
  to,
  secret
}: ActionRedirectProps): null {
  const history = useHistory();
  React.useEffect(() => {
    history.replace(to + "?s=" + secret);
  }, [history, secret, to]);
  return null;
}