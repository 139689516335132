/**
 * @generated SignedSource<<e4568f3e545ea2e121ce7c18d02847fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TenantList_Tenants$data = {
  readonly tenants: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"TenantList_Tenant">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "TenantList_Tenants";
};
export type TenantList_Tenants$key = {
  readonly " $data"?: TenantList_Tenants$data;
  readonly " $fragmentSpreads": FragmentRefs<"TenantList_Tenants">;
};
const node: ReaderFragment = function () {
  var v0 = ["tenants"];
  return {
    "argumentDefinitions": [{
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    }, {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }],
    "kind": "Fragment",
    "metadata": {
      "connection": [{
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": v0 /*: any*/
      }],

      "refetch": {
        "connection": {
          "forward": {
            "count": "count",
            "cursor": "cursor"
          },
          "backward": null,
          "path": v0 /*: any*/
        },

        "fragmentPathInResult": ["viewer"],
        "operation": require('./TenantListPaginationQuery.graphql')
      }
    },
    "name": "TenantList_Tenants",
    "selections": [{
      "alias": "tenants",
      "args": null,
      "concreteType": "TenantConnection",
      "kind": "LinkedField",
      "name": "__TenantList_tenants_connection",
      "plural": false,
      "selections": [{
        "alias": null,
        "args": null,
        "concreteType": "TenantEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [{
          "alias": null,
          "args": null,
          "concreteType": "Tenant",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [{
            "args": null,
            "kind": "FragmentSpread",
            "name": "TenantList_Tenant"
          }, {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }],
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cursor",
          "storageKey": null
        }],
        "storageKey": null
      }, {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [{
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endCursor",
          "storageKey": null
        }, {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasNextPage",
          "storageKey": null
        }],
        "storageKey": null
      }],
      "storageKey": null
    }],
    "type": "Viewer",
    "abstractKey": null
  };
}();
(node as any).hash = "f7e9f423ec448b0bc613979fbbf07c91";
export default node;