import _AccountSetNewPasswordRouteMutation from "./__generated__/AccountSetNewPasswordRouteMutation.graphql";
import { graphql, newId, useHistory, useLocation, useMutation } from "@workflows/runtime-web";
import { Callout, FormValues, Heading, Stack, StackItem, useDocumentTitle } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { AccountSetNewPasswordRouteMutation } from "./__generated__/AccountSetNewPasswordRouteMutation.graphql";
import { AccountLayout } from "./AccountLayout";
import { AccountSetNewPasswordForm } from "./AccountSetNewPasswordForm";
function useSearchParam() {
  return new URLSearchParams(useLocation().search);
}
export function AccountSetNewPasswordRoute(): JSX.Element {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const {
    t
  } = useTranslation("ai.workflows.accounts");
  const history = useHistory();
  const secret = useSearchParam().get("s") ?? "";
  useDocumentTitle(t("AccountSetNewPasswordRoute.title"));
  const [setNewPassword] = useMutation<AccountSetNewPasswordRouteMutation>(mutation);
  const onSubmit = async (values: FormValues) => {
    try {
      setNewPassword({
        variables: {
          input: {
            clientMutationId: newId(),
            password: (values.password as string),
            secret
          }
        },
        onError(error) {
          console.error(error);
          showErrorMessage("unspecified");
        },
        onCompleted(data) {
          data.updateUserPassword?.errors ? showErrorMessage((data.updateUserPassword?.errors[0]?.code as string)) : history.push("/sign-in");
        }
      });
    } catch (err) {
      console.error(err);
      showErrorMessage("unspecified");
    }
  };
  const showErrorMessage = (code: string) => {
    setError(code);
    setLoading(false);
  };
  return <AccountLayout>
      <Stack direction="vertical" fill justify="center" align="center">
        <StackItem className="w--full" style={{
        maxWidth: "30rem"
      }}>
          <Stack direction="vertical">
            <Heading size="lg" className="mb-md">
              {t("AccountSetNewPasswordRoute.title")}
            </Heading>
            {error && <Callout intent="critical" className="mb-md">
                {t([`AccountSetNewPasswordRoute.errors.${error}`, "AccountSetNewPasswordRoute.errors.unspecified"])}
              </Callout>}
            <AccountSetNewPasswordForm onSubmit={onSubmit} loading={loading} />
          </Stack>
        </StackItem>
      </Stack>
    </AccountLayout>;
}
const mutation = (_AccountSetNewPasswordRouteMutation.hash && _AccountSetNewPasswordRouteMutation.hash !== "31fc36d3493e3d381ada6cc41b211177" && console.error("The definition of 'AccountSetNewPasswordRouteMutation' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _AccountSetNewPasswordRouteMutation);