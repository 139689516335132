import _useMattersQuery from "./__generated__/useMattersQuery.graphql";
import { useLazyLoadQuery, graphql } from "@workflows/runtime-web";
import { FilterInputType, useTenantId } from "@workflows/ui";
import { useMattersQuery } from "./__generated__/useMattersQuery.graphql";
interface UseMattersProps {
  filter?: FilterInputType | null;
  first?: number;
}
export const useMatters = ({
  filter,
  first
}: UseMattersProps) => {
  const tenantId = useTenantId();
  return {
    fragment: useLazyLoadQuery<useMattersQuery>(mattersQuery, {
      tenantId,
      filter,
      first
    }, {
      fetchPolicy: "network-only"
    })
  };
};
const mattersQuery = (_useMattersQuery.hash && _useMattersQuery.hash !== "50b83d52147dcb4ec4bc0105b3efadde" && console.error("The definition of 'useMattersQuery' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data."), _useMattersQuery);